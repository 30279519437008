import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Grid from "@cloudscape-design/components/grid";
import Box from "@cloudscape-design/components/box";
import UIBox from "@mui/material/Box";
import Typography from '@mui/material/Typography';

import { v4 as uuid4 } from 'uuid';

import ButtonForm from "../components/buttonForm";
import SignInForm from "../components/signinForm";
import Cover from "../components/cover";
import { Notifications } from '../../../components/notifications/notifications';
import { signinI18nStrings } from '../../../i18-strings/signin';
import { passwordResetI18nStrings } from '../../../i18-strings/passwordReset';
import { createErrorNotification } from '../../../utils/createErrorNotification';
import { createSuccessNotification } from '../../../utils/createSuccessNotification';
import { PASSWORD_RESET_FORM_CONFIG} from '../utils/passwordResetConfig';


//Context
import {
  useContextController,
  setUser,
  setNotifications,
  setCognitoUser,
} from "../../../context/index";

//API
import { Auth } from 'aws-amplify';

export default function PasswordReset () {
  const [fetching] = useState(false)

  //context hook
  const [controller, dispatch] = useContextController();
  const { notifications, cognitoUser } = controller;
  //page redirection
  const navigate = useNavigate()

  const defaultResetState = {
    oldPassword: '',
    focusPassword: false,
    seeOldPassword: false,
    newPassword: '',
    focusNewPassword: false,
    seeNewPassword: false,
    newPasswordConfirmation: '',
    focusNewPasswordConfirmation: false,
    seeNewPasswordConfirmation: false,
    
  };

  const [resetState, setResetState] = useState(defaultResetState)

  //Pasword validation function==========================================================================
  const passwordValidation = (password, confirmPassword) =>{
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;

    const passwordLength =      password.length;
    const uppercasePassword =   uppercaseRegExp.test(password);
    const lowercasePassword =   lowercaseRegExp.test(password);
    const digitsPassword =      digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword =   minLengthRegExp.test(password);
    let errCode ="";
    let err = false;

    if(password!==confirmPassword){
        errCode = "PasswordDoNotMatchError";
        err=true;
    }else {
        if(passwordLength===0){
            errCode = "EmptyPasswordError";
            err=true;
        }else if(!minLengthPassword){
            errCode = "PasswordLengthError"; 
            err=true;      
        }else if(!uppercasePassword){
              errCode = "UppercasePasswordError";
              err=true; 
        }else if(!lowercasePassword){
            errCode = "LowercasePasswordError";
            err=true; 
        }else if(!digitsPassword){
            errCode = "DigitsPasswordError";
            err=true; 
        }else if(!specialCharPassword){
            errCode = "SpecialCharPasswordError";
            err=true; 
        }    

    }

    if(err){
        notifications[uuid4()] = createErrorNotification({name: errCode})
        setNotifications(dispatch, notifications)
    }
    
    return err;
  }

//Reset fucntion to handle password reset with cognito===============================================================
  async function handleReset() {
    if (!passwordValidation(resetState.newPassword, resetState.newPasswordConfirmation)) {
      try{
        const result = await Auth.completeNewPassword(cognitoUser, resetState.newPassword)
        const response = await  Auth.userAttributes(cognitoUser);
        const userObject = Object.assign({}, ...response.map((x) => ({[x.Name]: x.Value})));
        setUser(dispatch, userObject)
        notifications[uuid4()] = createSuccessNotification("passwordReset")
        setNotifications(dispatch, notifications)
        setTimeout(()=> {
          navigate("/dashboard");
         }, 2000);
      }
      catch(error){
        notifications[uuid4()] = createErrorNotification("PasswordResetError")
        setUser(dispatch, null)
        setCognitoUser(dispatch, null)
        setTimeout(()=> {
          navigate("/signin");
         }, 2000);
      }
    }
  }
  
  async function handleCoverToggle() {
    
  }

  return (
    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]} >  
      <UIBox
      position="absolute" top={0} left={0} 
      width={"100%"} 
      height={"125%"}
      >
      <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xxxl", right: "xxxl", left: "xxxl"}}>  
        <Cover showSignUp={false}/>
        <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xl", right: "xl", left: "xl"}}>
          <SignInForm
            header={passwordResetI18nStrings.resetHeaderForm}
            description={passwordResetI18nStrings.resetDescriptionForm}
            items={PASSWORD_RESET_FORM_CONFIG}
            state={resetState}
            setState={setResetState}
          />
          <ButtonForm text='Reset' handleClick={handleReset} loading={fetching}/>
        </Box>
        <Notifications/>
      </Box> 
      </UIBox>  
      <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xxxl", right: "xxxl", left: "xxxl"}}>
        <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xl", right: "xl", left: "xl"}}>
        <SignInForm
            header={passwordResetI18nStrings.resetHeaderForm}
            description={passwordResetI18nStrings.resetDescriptionForm}
            items={PASSWORD_RESET_FORM_CONFIG}
            state={resetState}
            setState={setResetState}
          />
          <ButtonForm text='Sign up' handleClick={handleReset} loading={fetching} testid="signup-button"/>
          <UIBox  display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
              <Typography mt={3} mr={1} variant="p" >{signinI18nStrings.signUpSwap}</Typography>
                <Typography mt={3} variant="p" component={Link} color='blue' onClick={handleCoverToggle} data-testid="signup-link">{signinI18nStrings.signUpLink}</Typography>
            </UIBox>
        </Box>
        <Notifications/>
      </Box>
    </Grid>  
  );
}