
import { I18nStringsModals } from '../../../i18-strings/modals';


export const APN_FORM_CONFIG = [
    {
      id: 'Apn',
      label: I18nStringsModals.bulkUploadApnLabel,
      focusId: 'focusApn',
      validatedId: 'isEmptyApn'
    },
    {
      id: 'ApnUser',
      label: I18nStringsModals.bulkUploadApnUserLabel,
      focusId: 'focusApnUser',
      validatedId: 'isEmptyApnUser'
    },
    {
      id: 'ApnPassword',
      label: I18nStringsModals.bulkUploadApnPasswordLabel,
      focusId: 'focusPassword',
      validatedId: 'isEmptyPassword'
    },
  ]
  
export function IsApnEmpty(state, setState) {
  let validationError = false
  const newState = { ...state };
  
  if (state["Apn"]=== null || state["Apn"]=== "") {
    validationError = true
    newState['isEmptyApn'] = true 
  }

  setState(newState)

  return validationError
}

export const CREATE_PROFILE_FORM_CONFIG = [
  {
    id: 'Iccid',
    label: I18nStringsModals.createProfileIccidLabel,
    focusId: 'focusIccid',
    validatedId: 'isEmptyIccid'
  },
  {
    id: 'ActivationCode',
    label: I18nStringsModals.createProfileActivationCodeLabel,
    focusId: 'focusActivationCode',
    validatedId: 'isEmptyActivationCode'
  },
  {
    id: 'Address',
    label: I18nStringsModals.createProfileAddressLabel,
    focusId: 'focusAddress',
    validatedId: 'isEmptyAddress'
  },
  {
    id: 'Apn',
    label: I18nStringsModals.createProfileApnLabel,
    focusId: 'focusApn',
    validatedId: 'isEmptyApn'
  },
  {
    id: 'ApnUser',
    label: I18nStringsModals.createProfileApnUserLabel,
    focusId: 'focusApnUser',
    validatedId: 'isEmptyApnUser'
  },
  {
    id: 'ApnPassword',
    label: I18nStringsModals.createProfileApnPasswordLabel,
    focusId: 'focusPassword',
    validatedId: 'isEmptyPassword'
  },
]