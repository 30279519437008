export function timestampToDate(timestamp) {
    const date = new Date(Number(timestamp)*1000)
    return date.toLocaleString("en-GB", {
        timezone: "UTC",
    })

}

export function getLastUpdateStatus(timestamp, triggerMinutes) {
  const currentTime = Date.now();
  const timeDifference =  currentTime - Number(timestamp*1000);

  const minutesPassed = Math.floor(timeDifference / (1000 * 60));

  if (minutesPassed > (triggerMinutes * (1+0,25))) {
    return "error"
  } else if (minutesPassed > triggerMinutes )
    return "warning"
  else
    return "success"


}

export function getIntervalSince(timestamp) {

  const currentTime = Date.now();
  const timeDifference =  currentTime - Number(timestamp*1000);

  // Get hours, minutes, and seconds
  const secondsPassed = Math.floor(timeDifference / 1000);
  const minutesPassed = Math.floor(timeDifference / (1000 * 60));
  const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
  let formattedTimePassed;

  if (daysPassed >= 2) {
    formattedTimePassed = `${daysPassed} days`;
  } else if (hoursPassed >= 1) {
    formattedTimePassed = `${hoursPassed} hours`;
  } else if (minutesPassed >= 1) {
    formattedTimePassed = `${minutesPassed} minutes`;
  } else {
    formattedTimePassed = `${secondsPassed} seconds`;
  }
  
  return formattedTimePassed;
}


export function interpolate(message, interpolations) {
    return Object.keys(interpolations).reduce(
      (interpolated, key) =>
        interpolated.replace(
          new RegExp(`{\s*${key}\s*}`, "g"),
          interpolations[key],
        ),
      message,
    );
  }

  export const save = (key, value) => localStorage.setItem(key, JSON.stringify(value));

  export const remove = (key) => localStorage.removeItem(key);
  
  export const load = (key) => {
    const value = localStorage.getItem(key);
    try {
      return value && JSON.parse(value);
    } catch (e) {
      console.warn(
        `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`
      );
      return undefined;
    }
  };

  