// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { useEffect, useState } from 'react';
import HelpPanel from '@cloudscape-design/components/help-panel';
import { useCollection } from '@cloudscape-design/collection-hooks';
import Button from '@cloudscape-design/components/button';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Icon from '@cloudscape-design/components/icon';

import Breadcrumbs from '../../components/breadcrumbs';
import Navigation from '../../components/navigation';
import ShellLayout from '../../layouts/shell';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Notifications } from '../../components/notifications/notifications';
import { COLUMN_DEFINITIONS_MAIN } from './utils/tableConfig';
import GenericTableP from '../../components/genericTable/genericTableP';
import { useApi } from '../../hooks/useApi';
import {fetchNucleusResource} from '../../api/nucleus'
import EmptyState from '../../components/genericTable/emptyState';
import CustomModal from '../../components/modals';

import { I18nStringsTables } from '../../i18-strings/tables';
import { I18nStringsButtons } from '../../i18-strings/buttons';

import {
  useContextController,
  setModal,
  setNucleusProfiles,
} from "../../context/index";
import { FILTERING_PROPERTIES } from './utils/filteringProperties';
import { DEFAULT_PREFERENCES } from './utils/defaults';

export default function ProfilesPage() {

  const [nucleusResponse, setNucleusResponse] = useApi()
  
  const [preferences, setPreferences] = useLocalStorage('React-Profiles-Preferences',DEFAULT_PREFERENCES);

  const [selectedProfilesCache, setSelectedProfilesCache] = useState([])

  //context hook
  const [controller, dispatch] = useContextController();
  const {nucleusProfiles} = controller

  async function refresh(){
    /*await setLoloResponse(()=>fetchLoloResource('stats')))*/
    const resp = await setNucleusResponse(()=>fetchNucleusResource('profiles'))
    const transformedData = resp.profiles.map(item => {
      // Extraer SKU desde "sku#status#ts"
      const sku = item["sku#status#ts"] ? item["sku#status#ts"].split("#")[0] : null;
      
      // Extraer status desde "status#ts"
      const status = item["status#ts"] ? item["status#ts"].split("#")[0] : null;
    
      // Devolver un nuevo objeto con los campos sku y status adicionales
      return {
        ...item,       // Mantiene los campos originales
        sku,           // Nuevo campo para sku
        status         // Nuevo campo para status
      };
    });
    setNucleusProfiles(dispatch, transformedData)
    
    
    if(resp.profiles){
      const updatedSelectedProfiles = resp.profiles.filter(elem => {
        return selectedProfilesCache.findIndex(a1elem => a1elem.iccid === elem.iccid) !== -1;
      });
      setSelectedProfilesCache(updatedSelectedProfiles)
    }
  }

  useEffect(() => {
    if (!nucleusProfiles) {
      refresh()
    }
  },[]);// eslint-disable-line react-hooks/exhaustive-deps

  // const handleDropdownClick=((id)=>{
  //   setModal(dispatch, {type: id, id: selectedProfilesCache[0].iccid, refresh: refresh })
  // })

  const handleButtonClick=((id)=>{
    setModal(dispatch, {type: id, refresh: refresh})
  })

  const tableActions = ( 
      <SpaceBetween size="xs" direction="horizontal">
      {/*<ButtonDropdown 
        disabled={selectedProfilesCache?.length === 0}
        items={[
          { text: I18nStringsButtons.deactivate, id: "deactivate", disabled: selectedProfilesCache?.length === 1 ? false : true },
          { text: I18nStringsButtons.delete, id: "delete", disabled: selectedProfilesCache?.length > 1 ? false : true },
        ]}
        onItemClick={({detail})=>{handleDropdownClick(detail.id)}} 
      >
      {I18nStringsButtons.actions}
      </ButtonDropdown>*/}
      <Button 
        variant="primary"
        onClick={()=>{handleButtonClick("createProfile")}} 
      >
        {I18nStringsButtons.create}
      </Button>
      <Button 
        onClick={()=>{handleButtonClick("bulkUpload")}} 
        variant="primary"
      >
          {I18nStringsButtons.bulkCreation}
      </Button>
      <Button onClick={refresh} loading={nucleusResponse.isFetching} ><Icon name="refresh"/></Button>
    </SpaceBetween>) 
  
  
  const { items, propertyFilterProps, actions, filteredItemsCount, paginationProps, collectionProps } = useCollection(
    nucleusProfiles ? nucleusProfiles : [],
    {
      pagination: { pageSize: preferences?.pageSize },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS_MAIN[0] } },
      selection: { },
      propertyFiltering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setPropertyFiltering({ tokens: [], operation: 'and' })}>Clear filter</Button>}
          />
        ),
        empty: (
          <EmptyState title="No profiles" subtitle="No profiles to display." action={<Button>Create profile</Button>} />
        ),
        filteringProperties: FILTERING_PROPERTIES
      }
    }
  );
    
  return (
    <ShellLayout
      contentType="table"
      breadcrumbs={<Breadcrumbs active={[{ text: 'Profiles', href: '/profiles' }]} />}
      navigation={<Navigation />}
      notifications={<Notifications/>}
      tools={<HelpPanel header={<h2>Help panel</h2>}></HelpPanel>}
    >
      <GenericTableP 
                items={items} 
                filterProps={propertyFilterProps} 
                actions={tableActions} 
                filteredItemsCount={filteredItemsCount} 
                paginationProps={paginationProps} 
                collectionProps={collectionProps} 
                preferences={preferences} 
                setPreferences={setPreferences} 
                isFetching={(nucleusResponse.isFetching && (!nucleusProfiles))} 
                loadingText={"Loading profiles"}
                refresh={refresh} 
                buttonLoading={nucleusResponse.isFetching} 
                onSelectionChange={({detail})=>{setSelectedProfilesCache(detail.selectedItems)}}
                selectedItems={selectedProfilesCache}
                columns={COLUMN_DEFINITIONS_MAIN}
                headerText={I18nStringsTables.profilesHeader}
                findTextPlaceholder={I18nStringsTables.profilesFind}
                variant="full-page"
                id="iccid"
                /> 
      <CustomModal/>
    </ShellLayout>
  );
}
