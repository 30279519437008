
import React, { useState, useEffect } from 'react';

import { Table, ColumnLayout, Box } from '@cloudscape-design/components';

import { CounterLink } from '../../../components/common-components';
import {COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE} from './panelConfig'

const EMPTY_PANEL_CONTENT = {
  header: '0 instances selected',
  body: 'Select an instance to see its details.',
};

export const GetPanelContent = (selectedDevices, refresh) => {

  if (!selectedDevices.length) {
    return EMPTY_PANEL_CONTENT;
  }
  else if (selectedDevices.length === 1) {
    return GetPanelContentSingle(selectedDevices[0], refresh);
  }
  else {
    return GetPanelContentMultiple(selectedDevices);
  }
};

export const GetPanelContentSingle = (item, refresh) => {
    
  return {
    header: item.eid + " (" + item.numProfiles + " profiles)",     
    body:  
        <Table
          columnDefinitions={COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE}
          items={
             item.profiles.map(profile=> {
              return{
              ...profile, // Spread the original object to retain its properties
              deviceStatus: item.status,
              eid: item.eid,
              refresh: refresh
              }
            })
          }
          variant="embedded"
          stripedRows={true}
        />
      
    
  }
};

export const GetPanelContentMultiple = (items) => {

  const numDevices = items.length

  const idle = items.filter(({ status }) => status === 'idle').length;
  const notIdle = items.filter(({ status }) => status !== 'idle').length;

  var activeOperatorsList = items.reduce((a, {activeOperator}) => {
    if (activeOperator in a) {
      a[activeOperator]++
    } 
    else {
      a[activeOperator] = 1
    }
    return a;
  }, {});

  const totalProfilesInstalled = items.reduce((totalProfilesInstalled, { profiles }) => {
    totalProfilesInstalled += profiles.length;
    return totalProfilesInstalled;
  }, 0);

  const avgProfilesInstalled = Math.round(totalProfilesInstalled/numDevices)


  return {
    header: `${items.length} devices selected`,
    body: (
      <ColumnLayout columns={4} variant="text-grid">
        <div>
          <Box variant="awsui-key-label">Total devices</Box>
          <CounterLink>{numDevices}</CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">Average profiles per device</Box>
          <CounterLink>{avgProfilesInstalled}</CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">Diferent active operators</Box>
          <CounterLink>{Object.keys(activeOperatorsList).length}</CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">Idle / Busy</Box>
          <CounterLink>{idle} / {notIdle}</CounterLink>
        </div>
      </ColumnLayout>
    ),
  };
};

export const useSplitPanel = (selectedItems) => {
  const [splitPanelSize, setSplitPanelSize] = useState(300);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);

  const onSplitPanelResize = (event) => {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelToggle = (event) => {
    setSplitPanelOpen(event.detail.open);

    if (!event.detail.open) {
      setHasManuallyClosedOnce(true);
    }
  };

  useEffect(() => {
    if (selectedItems.length && !hasManuallyClosedOnce) {
      setSplitPanelOpen(true);
    }
  }, [selectedItems.length, hasManuallyClosedOnce]);

  return {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
  };
};