import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { PollNotification } from "./PollNotification";
import { createSuccessNotification } from '../../utils/createSuccessNotification';
import {Auth} from 'aws-amplify'

const baseURL = process.env.REACT_APP_ENV_API_URL;

  // axios.interceptors.request.use(request => {
  //       console.log('Starting Request', JSON.stringify(request, null, 2))
  //       return request
  //   })
  // axios.interceptors.response.use(request => {
  //     console.log('Response recivied', JSON.stringify(request, null, 2))
  //     return request
  // })

// Handles Put Requests
export const deleteNucleusResource = async (resource, payload) => {
  
  try {
    const session = await Auth.currentSession();

    const delete_payload = {
      headers: { 'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` },
      data: {
        operator: payload['operator'],
        owner_id: session.accessToken.payload.username
      }

    }
    const response = await axios.delete(`${baseURL}/${resource}`, delete_payload) 
    return response.data
    
  } catch (err) {
    throw err;
  } 
};

// Handles Put Requests
export const putNucleusResource = async (resource, payload) => {
  try {
    const session = await Auth.currentSession();
    payload['owner_id']= session.accessToken.payload.username
    const json = JSON.stringify(payload);

    const nucleus_headers = {headers: {'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` }};
    const response = await axios.put(`${baseURL}/${resource}`, json, nucleus_headers)
    return response.data
    
  } catch (err) {
    throw err;
  } 
};

// Handles POST Requests
export const postNucleusResource = async (resource, payload) => {
  try {
    const session = await Auth.currentSession();
    payload['owner_id']= session.accessToken.payload.username
    const json = JSON.stringify(payload);

    const nucleus_headers = {headers: {'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` }};
    const response = await axios.post(`${baseURL}/${resource}`, json, nucleus_headers)
    return response.data
    
  } catch (err) {
    throw err;
  } 
};

// Handles GET Requests
export const fetchNucleusResource = async (resource) => {
  
  try {
    const session = await Auth.currentSession();
    const nucleus_headers = {headers: {'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` }};
    var url =''
    if (resource.includes('?')) {
      url = `${baseURL}/${resource}&limit=1000`
    }
    else {
      url = `${baseURL}/${resource}?limit=1000`  
    }
    const response = await axios.get(url, nucleus_headers)
    return response.data
    
  } catch (err) {
    throw err;
  } 
};

// Handles GET Requests by Id 
export const fetchNucleusResourceById = async (resource, id) => {
  try {
    const session = await Auth.currentSession();
    const config = {
      headers: {'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` }, 
    };
    const response = await axios.get(`${baseURL}/${resource}/${id}`, config)
    return response.data
    
  } 
  catch (err) {
    throw err;
  }
};

// Handles DELETE Requests by Id 
export const deleteNucleusResourceById = async (resource, id) => {
  try {
    const session = await Auth.currentSession();
    const delete_payload = {
      headers: { 'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` },
    }
    const response = await axios.delete(`${baseURL}/${resource}/${id}`, delete_payload)
    return response.data
    
  } 
  catch (err) {
    throw err;
  }
};

export const profilesBulkUpload = async (file, payload) => {
  try {
    const session = await Auth.currentSession();
    const nucleus_headers = {headers: {'Authorization': `Bearer ${session.getIdToken().getJwtToken()}`, 'Content-Type': 'multipart/form-data' }};
    let formData = new FormData();
    formData.append("file", file);
    formData.append("payload", JSON.stringify(payload));
    
    const response = await axios.post(`${baseURL}/profiles/bulk`, formData, nucleus_headers)
    return response.data
    
  } catch (err) {
    throw err;
  } 
};

// Handles enable, delete, and install commands
export const executeCommand = async (command, parameters, notificationCallback) => {
  let payload = {}
  const session = await Auth.currentSession();
  const config = {
    headers: {'Authorization': `Bearer ${session.getIdToken().getJwtToken()}`}, 
  };
  
  if (command === 'Enable'){
    payload = {
        iccid: parameters['iccid'],
    };
  }

  if (command === 'Reinstall') {
    payload = {
        iccid: parameters['iccid'],
        enable: false
    };
  }

  if (command === 'INSTALL_MANUAL') {
    payload = {
        activation_code: parameters['activationCode'],
        address: parameters['address'],
      };
  }

  if (command === 'INSTALL_AUTO') {
    payload = {
       sku: parameters['sku'],
       enable: false
      };
  }
  
  const json = JSON.stringify(payload);
  
  try {
    if (command === 'Enable') {
      const response = await axios.patch(`${baseURL}/devices/${parameters['eid']}/profiles`, json, config) 
      PollNotification(response['data']['requestId'], notificationCallback, parameters['eid'], command)
      return response
    }
    if (command === 'Reinstall') {
      const response = await axios.put(`${baseURL}/devices/${parameters['eid']}/profiles`, json, config) 
      PollNotification(response['data']['requestId'], notificationCallback, parameters['eid'], command)
      return response
    }
    if (command === 'Delete') {
      const delete_payload = {
        headers: { 'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` },
        data: {
          iccid: parameters['iccid']
        }

      }
      const response = await axios.delete(`${baseURL}/devices/${parameters['eid']}/profiles`, delete_payload) 
      if (response['data']['message_id'] !== "NONE"){
        PollNotification(response['data']['requestId'], notificationCallback, parameters['eid'], command)
        return response
      }
      else {
        const notification = {}
        notification[uuidv4()] = createSuccessNotification("success", parameters['eid'], command)
        notificationCallback(notification)
        return response
      }
      
      
    }
    if ((command === 'INSTALL_MANUAL') || (command === 'INSTALL_AUTO')) {
      const response = await axios.post(`${baseURL}/devices/${parameters['eid']}/profiles`, json, config) 
      PollNotification(response['data']['requestId'], notificationCallback, parameters['eid'], command)
      return response
    }
  }
  catch (err) {
    throw err;
  }
      
}