import { signinI18nStrings } from '../../../i18-strings/signin'


export const SIGNIN_FORM_CONFIG = [
    {
      id: 'AccountNumber',
      label: signinI18nStrings.signInAccountNumberLabel,
      focusId: 'focusAccountNumber',
      validatedId: 'isEmptyAccountNumber'
    },
    {
      id: 'UserName',
      label: signinI18nStrings.signInUserNameLabel,
      focusId: 'focusUserName',
      validatedId: 'isEmptyUserName'
    },
    {
      id: 'Password',
      label: signinI18nStrings.signInPasswordLabel,
      focusId: 'focusPassword',
      validatedId: 'isEmptyPassword'
    },
  ]
  
export function validateOnlyEmptyFields(state, setState) {
  let validationError = false
  const newState = { ...state };
  for (let key in state) {
    if (state[key]=== null || state[key]=== "") {
     validationError = true
     newState['isEmpty'+key] = true
    }
  }

  setState(newState)

  return validationError
}
  
export const SIGNUP_FORM_CONFIG = [
    {
      id: 'AccountName',
      label: signinI18nStrings.signUpAccountNameLabel,
      focusId: 'focusAccountName',
      validatedId: 'isEmptyAccountName'
    },
    {
      id: 'UserName',
      label: signinI18nStrings.signUpUserNameLabel,
      focusId: 'focusUserName',
      validatedId: 'isEmptyUserName'
    },
    {
      id: 'Email',
      label: signinI18nStrings.signUpEmailLabel,
      focusId: 'focusEmail',
      validatedId: 'isEmptyEmail'
    },
  ]
 