import * as React from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

const style ={
    borderRadius: 10,
    textTransform: 'none',
    backgroundColor: '#192534', 
    fontSize:'15px', 
    padding:'6px'
    
}

const loadingStyle ={
    borderRadius: 10,
    textTransform: 'none', 
    fontSize:'15px', 
    padding:'6px'
    
}

export default function ButtonForm({text, handleClick, loading, testid }) {
  return (
    <Box textAlign= 'center' margin={2}>
        <LoadingButton 
        variant="contained" 
        fullWidth={true}
        style={loading ? loadingStyle : style}
        onClick={handleClick}
        loading={loading}
        data-testid = {testid}
        >
        <span>{text}</span>
        </LoadingButton>
    </Box>  
  );
}