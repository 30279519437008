import * as React from 'react';

import UIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import lgimage from "../../../assets/images/logo-light.png"
import { signinI18nStrings } from '../../../i18-strings/signin';

export default function Cover({showSignUp}) {

    const sx ={
        backgroundColor: '#192534', 
        zIndex:'5000', 
        transform: showSignUp ? "translateX(0%)" : "translateX(100%)", 
        transition: "transform 0.5s ease"
    }

    return (
        <UIBox 
            data-testid="cover" 
            position="absolute" top={0} left={0} 
            width={"100%"} 
            height={"100%"} sx={ sx }
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <UIBox component="img" src={lgimage}  maxWidth="50%"></UIBox>
            <Typography color="white" mt={3} variant="h4" >{signinI18nStrings.coverHeader}</Typography>
            <Typography color="white" mt={3} variant="subtitle1">{signinI18nStrings.coverDescription}</Typography>   
        </UIBox>
    );
}