import { useState } from "react";

import Modal from "@cloudscape-design/components/modal";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from '@cloudscape-design/components/space-between';
import Box from "@cloudscape-design/components/box";
import Toggle from "@cloudscape-design/components/toggle";
import TextContent from "@cloudscape-design/components/text-content";

import { profilesBulkUpload } from "../../api/nucleus";
import { useSyncApiWithFeedback } from "../../hooks/useSyncApiWithFeedback";
import FileUploader from "../fileUploader/fileUploader";
import GenericForm from "../genericForm/genericForm";
import { APN_FORM_CONFIG, IsApnEmpty } from "./utils/utils";

import { I18nStringsButtons } from "../../i18-strings/buttons";
import { I18nStringsModals } from "../../i18-strings/modals";

export default function ProfilesBulkUpload ({ modal, handleDismiss }) {

  const [files, setFiles] = useState([]);
  const [errorText, setErrorText] = useState(null)
  const [apnManagement, setApnManagement] = useState(false)

  const[commandResponse, setCommandResponse] = useSyncApiWithFeedback()

  const [selectedOperator, setSelectedOperator] = useState(null);

  const defaultApnFormState = {
    Apn: '',
    focusApn: false,
    isEmptyApn: false,
    ApnUser: '',
    focusApnUser: false,
    isEmptyApnUser: false,
    Password: '',
    focusPassword: false,
    isEmptyPassword: false,
    
  };

  const [apnFormState, setApnFormState] = useState(defaultApnFormState)

  function handleUpload(){
    if (!selectedOperator){
      setErrorText(I18nStringsModals.bulkUploadOperatorError);
      return
    }
    const payload = {sku: selectedOperator.value}
    if (!apnManagement){
      if (files.length === 0) {
        setErrorText(I18nStringsModals.bulkUploadFileError);
        return
      }
      setCommandResponse (() => profilesBulkUpload(files[0], payload));
    }
    else {
      if (! IsApnEmpty(apnFormState, setApnFormState)){
        payload['apn']= apnFormState.Apn
        var apnUser = apnFormState.ApnUser;
        var apnPassword = apnFormState.Password;

        if (apnUser !== '') {
            payload['apnUser'] = apnUser;
        }

        if (apnPassword !== '') {
            payload['apnPassword'] = apnPassword;
        }

        setCommandResponse (() => profilesBulkUpload(files[0], payload))
      }
    }
    modal.refresh()
    handleDismiss()

    
  }

  return (
    <Modal 
      header={I18nStringsModals.bulkUploadHeader} 
      onDismiss={handleDismiss} 
      visible={true}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={handleDismiss}>{I18nStringsButtons.cancel}</Button>
          <Button 
            variant="primary" 
            onClick={handleUpload}
          >
            {I18nStringsButtons.upload}
          </Button>
          </SpaceBetween>
          </Box>
          }
    >
      
      <FileUploader
        fileInputId="singleFileUpload"
        text={I18nStringsModals.bulkUploadButtonText}
        description={I18nStringsModals.bulkUploadDescription}
        errorText={errorText}
        setErrorText={setErrorText}
        files={files}
        selectedOption={selectedOperator}
        setSelectedOption={setSelectedOperator}
        handledismiss={handleDismiss}
        onFilesUploaded={(uploadedFiles) => {
          if (uploadedFiles.length === 1) {
          setFiles([...uploadedFiles]);
          setErrorText("");
          } else {
          setErrorText(I18nStringsModals.bulkUploadError);
          }
        }}
      />
      
        <SpaceBetween direction="horizontal" size="xl">
          <TextContent><h5>{I18nStringsModals.bulkUploadAPNHeader}</h5></TextContent>
          <Toggle checked={apnManagement} onChange={({ detail }) =>setApnManagement(detail.checked)}/>
        </SpaceBetween>
        <TextContent><p>{I18nStringsModals.bulkUploadApnDisclaimer}</p></TextContent>
        {apnManagement ? <GenericForm
            items={APN_FORM_CONFIG}
            state={apnFormState}
            setState={setApnFormState}
          /> :''}
    </Modal>
  )
}