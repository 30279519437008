
import React from 'react';
import AppLayout from '@cloudscape-design/components/app-layout';
import TopNavigationMenu from '../utils';

import './styles.css';

export default function Shell({ children, contentType, breadcrumbs, tools, navigation, notifications }) {
  return (
    <>
      <TopNavigationMenu/>
      <AppLayout
        contentType={contentType}
        navigation={navigation}
        breadcrumbs={breadcrumbs}
        notifications={notifications}
        stickyNotifications={true}
        tools={tools}
        content={children}
        headerSelector="#top-nav"
        ariaLabels={{
          navigation: 'Navigation drawer',
          navigationClose: 'Close navigation drawer',
          navigationToggle: 'Open navigation drawer',
          notifications: 'Notifications',
          tools: 'Help panel',
          toolsClose: 'Close help panel',
          toolsToggle: 'Open help panel',
        }}
      />
    </>
  );
}
