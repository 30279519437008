import { useState, useEffect } from "react";
import { Select } from "@cloudscape-design/components"
import { I18nStringsModals } from "../../i18-strings/modals.js";
//Context
import {
  useContextController,
  setOperators,
} from "../../context/index.js";

import {fetchNucleusResource} from '../../api/nucleus'
import { useApi } from '../../hooks/useApi';

export default function OperatorSelect(){

  const [nucleusResponse, setNucleusResponse] = useApi()

  //context hook
  const [controller, dispatch] = useContextController();
  const { operators } = controller;

  const [options, setOptions] = useState([])
  const [setErrorText] = useState(null)

  async function refresh() {
    const resp = await setNucleusResponse(()=>fetchNucleusResource('operators'))
    await setOperators(dispatch, resp.operators)
    const operatorsMenu = []
    resp.operators.map((operator)=>{
      if (operator['inventory'] > 0) {  
      operatorsMenu.push({label: operator['type#key'].split('#')[1], value: operator['type#key'].split('#')[1]})
      }
    })
    setOptions(operatorsMenu)
    }

    useEffect(() => {
    if(!operators){
      refresh()
    }

    else {
      const operatorsMenu = []
      operators.map((operator)=>{
      if (operator['inventory'] > 0) {
        operatorsMenu.push({label: operator['type#key'].split('#')[1], value: operator['type#key'].split('#')[1]}) 
      } 
      })
      setOptions(operatorsMenu)
    }
    

    }, []);

    const [
    selectedOption,
    setSelectedOption
    ] = useState(null);

  return (
    <Select
      selectedOption={selectedOption}
      onChange={({ detail }) =>{
        setSelectedOption(detail.selectedOption)
        setErrorText(null)
      }}
      options={options}
      errorText={I18nStringsModals.errorFetchingOperators}
      placeholder={I18nStringsModals.chooseOperatorPlaceholder}
      statusType={nucleusResponse.isFetching ? "loading" : nucleusResponse.error ? "error" : "success"}
      empty={I18nStringsModals.chooseOperatorEmpty}
      expandToViewport = {true}
    />
  )
}
