// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useState } from 'react';
import { Header, PieChart, Box, ButtonDropdown } from '@cloudscape-design/components';
import { percentageFormatter } from '../chart-commons';
import { ResponsiveChartContainer } from '../../responsive-chart-container';

import {
  useContextController,
} from "../../../../context/index";
import { getDDropddownList, getStatesProfilesDistribution } from '../../../../utils/NucleusDataFormatter';

export const stateProfilesDistribution = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'pieChart',
    title: 'State profiles distribution',
    description: 'Profiles distribution per state and operator',
    header: ZoneStatusHeader,
    content: ZoneStatusContent,
    staticMinHeight: 450,
  },
};

function ZoneStatusHeader() { 
  return (  
    <Header variant="h2" description="Data on profiles states">
      State profiles distribution
    </Header> 
  );
}

function getChartSize(height) {
  if (height < 150) {
    return 'small';
  }
  if (height < 300) {
    return 'medium';
  }
  return 'large';
}

function ZoneStatusContent() {
  //context hook
  const [controller] = useContextController();
  const {operators} = controller

  const results = operators ? getStatesProfilesDistribution(operators) : []
  const [filteredData, setFilteredData] = useState()

  const dropdown = getDDropddownList(operators)
  const[dropdownText, setDropdownText] = useState("Filter by operator")
  
  const hideFilter = true;

  function handleDropdownClick(id) {
    if (id === 'reset') {
      setFilteredData(null)
      setDropdownText('Filter by operator')
    }else {
      var operator = operators.filter(item => item['type#key'].split('#')[1] === id)
      setDropdownText(operator[0]['type#key'].split('#')[1])
      setFilteredData(getStatesProfilesDistribution(operator)[0])
    }  
  }

  return (
    <>
    <ButtonDropdown
    items={dropdown}
    onItemClick={({detail})=>{handleDropdownClick(detail.id)}} 
    expandToViewport = {true}
  >
    {dropdownText}
  </ButtonDropdown>
    <ResponsiveChartContainer
      hideFilter={hideFilter}
      render={height => (
        <PieChart
          empty={
            <Box textAlign="center" color="inherit">
              <b>No data available</b>
              <Box variant="p" color="inherit">
                No active profiles
              </Box>
            </Box>
          }
          variant='donut'
          innerMetricDescription="total"
          innerMetricValue={results[1]}
          size={getChartSize(height)}
          data={
            filteredData ? filteredData : operators ? results[0] :[]
          }
          hideLegend={true}
          hideFilter={hideFilter}
          segmentDescription={(datum, sum) => `${datum.value} profiles, ${percentageFormatter(datum.value / sum)}`}
          detailPopoverContent={(datum, sum) => [
            {
              key: 'Profiles count',
              value: datum.value,
            },
            {
              key: 'Percentage',
              value: percentageFormatter(datum.value / sum),
            },
          ]}
        />
      )}
    />
    </>
  );
}


