export const notificationsI18nStrings = {
    default: 'There was an error, please try again later',
    fetchingError: 'There was an issue fetching cloud information',
    NotCurrentUser: 'User session has expired, please login again',
    deviceBusy: 'Device is busy executing {command} command, please try again later',
    userNotFound: 'Your authentication information is incorrect. Please try again',
    wrongPassword: 'Your authentication information is incorrect. Please try again',
    emptyPasword: 'Please enter a new password',
    passworrdLength: 'Password must be at least 8 characters long',
    uppercasePassword: 'Password must contain at least one uppercase letter',
    lowercasePassword: 'Password must contain at least one lowercase letter',
    digitsPassword: 'Password must contain at least one digit',
    specialCharPassword: 'Password must contain at least one special character',
    passwordDoNotMatch: 'Passwords do not match',
    passwordResetConfirmation: 'Your password has been reset, you will be redirected to your dahsboard in a few seconds',
    passwordResetError: 'There was an error resetting your password, you will be redirected to login screen in a few seconds',
    simError: 'The SIM card was not able to execute the comand with error: {error}',
    successCommand: 'Your {command} command on device with eid: {eid} was executed successfully with result: {result}',
    syncSuccess: 'Operation successful',
    infoCommand: 'Your command is on its way',
    infoOperation: 'Executing operation',
    timeout: 'We have been unable to retireve confirmation on command {command} from the device with eid: {eid}, please refresh in a while',
    notFound: 'Resource not found, refresh and try again',
  };