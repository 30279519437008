import { useState } from "react";

import {TextContent as Text} from "@cloudscape-design/components";
import Modal from "@cloudscape-design/components/modal";
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Box from "@cloudscape-design/components/box";

import GenericForm from "../genericForm/genericForm";
import OperatorSelect from "../operatorSelect";
import { CREATE_PROFILE_FORM_CONFIG } from "./utils/utils";

import { I18nStringsModals } from "../../i18-strings/modals";
import { I18nStringsButtons } from "../../i18-strings/buttons";



export default function ProfilesModalCreate ({handleDismiss, handleAction, modal, monitored=true}) {

  const [value] = useState("");
  const [setError] = useState(false)

  const defaultFormState = {
    Iccd: '',
    focusIccid: false,
    isEmptyIccid: false,
    ActivationCode: '',
    focusActivationCode: false,
    isEmptyActivationCode: false,
    Address: '',
    focusAddress: false,
    isEmptyAddress: false,
  };

  const [formState, setFormState] = useState(defaultFormState)

  return (
    <Modal
      onDismiss={handleDismiss}
      visible={true}
      footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
        <Button variant="link" onClick={handleDismiss}>{I18nStringsButtons.cancel}</Button>
        <Button 
          variant="primary" 
          onClick={()=> {
              handleAction("createProfile", value)
              setError(false)
          }}
        >
          {I18nStringsButtons.create}
        </Button>
        </SpaceBetween>
        </Box>
        }
      header={I18nStringsModals.createProfileHeader}
    >
      <SpaceBetween direction="vertical" size="s">
        <Text>{I18nStringsModals.createProfileDescription}</Text>
        <GenericForm
          items={CREATE_PROFILE_FORM_CONFIG}
          state={formState}
          setState={setFormState}
          children={<Box>Select operator<OperatorSelect/></Box>}
        >

        </GenericForm>
        
      </SpaceBetween>
    </Modal>
  );
}