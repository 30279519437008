
import { useNavigate } from 'react-router-dom';
import Link from "@cloudscape-design/components/link";

import {
    useContextController,
    setSelectedDevice,
    setNucleusDeviceNotifications,
    setActiveHref,
  } from "../../context/index";

import { useApi } from '../../hooks/useApi';
import {fetchNucleusResource} from '../../api/nucleus'
import {fetchNucleusResourceById} from '../../api/nucleus'

export default function TableLink({item}) {

    const [, setNucleusResponse] = useApi()
    const [, setNotificationsResponse] = useApi()

    //context hook
    const [, dispatch] = useContextController();

  const navigate = useNavigate();

  async function getDevice(){
    /*await setLoloResponse(()=>fetchLoloResource('stats')))*/
    const resp = await setNucleusResponse(()=>fetchNucleusResourceById("devices",item.eid))
    setSelectedDevice(dispatch,resp.device)
    const query = encodeURIComponent(JSON.stringify({ eid: item.eid }))
    const resource = `notifications?filter=${query}`
    const notifications = await setNotificationsResponse(()=>fetchNucleusResource(resource))
    setNucleusDeviceNotifications(dispatch, notifications.notifications)
    
  }

  async function getNotification(){
    const query = encodeURIComponent(JSON.stringify({ eid: item.eid }))
    const resource =  `notifications?filter=${query}`
    const notifications = await setNotificationsResponse(()=>fetchNucleusResource(resource))
    setNucleusDeviceNotifications(dispatch, notifications.notifications)
  }

  function setCorrectDevice() {
    if (item.iccid) {
      getDevice()
      setSelectedDevice(dispatch,null)
      setNucleusDeviceNotifications(dispatch, null)
    }
    else {  
      getNotification() 
      setSelectedDevice(dispatch,item)
      
    }
  }

  return (
    <Link 
      href="#"
      onFollow={async(event) =>{
        event.preventDefault();
        setCorrectDevice()
        setActiveHref(dispatch, "devices/device-detail");
        navigate("/devices/device-detail");
      }
      }
    >
    {item.eid}
    </Link>
  )
};