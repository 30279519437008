

export default function SeparatedList({ ariaLabel, items }) {
  return (
    <ul aria-label={ariaLabel}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}