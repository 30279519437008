
import React from 'react';

import CollectionPreferences from '@cloudscape-design/components/collection-preferences';
import Header from '@cloudscape-design/components/header';
import Pagination from '@cloudscape-design/components/pagination';
import Table from '@cloudscape-design/components/table';
import { PropertyFilter } from '@cloudscape-design/components';

import { I18nStringsButtons } from '../../i18-strings/buttons';
import { I18nStringsPreferences } from '../../i18-strings/preferences';

const getFilterCounterText = (count = 0) => `${count} ${count === 1 ? 'match' : 'matches'}`;

const getHeaderCounterText = (items = [], selectedItems = []) => {
  return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${items.length})` : `(${items.length})`;
};

export default function GenericTableP({ items, filterProps, actions, filteredItemsCount, paginationProps, collectionProps, preferences, setPreferences, isFetching, onSelectionChange, selectedItems, findTextPlaceholder, headerText, columns, variant, id, loadingText}) {
  return (
    <Table
      {...collectionProps}
      items={items}
      columnDefinitions={columns}
      stickyHeader={true}
      resizableColumns={true}
      variant={variant}
      selectionType="multi"
      stripedRows={true}
      loading={ isFetching }
      loadingText={loadingText}
      onSelectionChange={onSelectionChange}
      selectedItems={selectedItems}
      trackBy={id}
      header={
        <Header
          variant="awsui-h1-sticky"
          counter={getHeaderCounterText(items, selectedItems)}
          actions={actions}
        >
          {headerText}
        </Header>
      }
      pagination={<Pagination {...paginationProps} />}
      filter={
        <PropertyFilter
          {...filterProps}
          i18nStrings= {{
            filteringAriaLabel: findTextPlaceholder,
            filteringPlaceholder: findTextPlaceholder,
          }}
          countText={getFilterCounterText(filteredItemsCount)}
          expandToViewport
          enableTokenGroups
        />
      }
      preferences={
        <CollectionPreferences
          preferences={preferences}
          pageSizePreference={{
            title: I18nStringsPreferences.pageSizeTitle,
            options: [
              { value: 10, label: I18nStringsPreferences.tenResources },
              { value: 20, label: I18nStringsPreferences.twentyResources },
              { value: 50, label: I18nStringsPreferences.fiftyResources },
              { value: 100, label: I18nStringsPreferences.hundredResources },
            ],
          }}
          onConfirm={({ detail }) => setPreferences(detail)}
          title={I18nStringsPreferences.title}
          confirmLabel={I18nStringsButtons.confirm}
          cancelLabel={I18nStringsButtons.cancel}
        />
      }
    />
  );
}
