// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import {Header, Button} from '@cloudscape-design/components';
import { EmbeddedTable } from '../../embedded-table-wrapper';

import {COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE} from '../../../../layouts/splitShell/utils/panelConfig';

import { SpaceBetween } from '@cloudscape-design/components';
import { COLUMN_DEFINITIONS_NON_EUICC_PROFILE_TABLE } from '../../../../config/nonEuiccProfileTableColumnDef';
import { isVisualRefresh } from '../../../../utils/apply-mode';


import { useApi } from '../../../../hooks/useApi';
import {fetchNucleusResource} from '../../../../api/nucleus'

import {
  useContextController,
  setSelectedDevice,
  setNucleusDevices,
  setModal,
} from "../../../../context/index";

export const profilesDetail = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 4 },
  data: {
    icon: 'table',
    title: 'Device Profiles Detail',
    description: 'View all your sim profiles',
    disableContentPaddings: !isVisualRefresh,
    header: ProfilesDetailHeader,
    content: ProfilesDetailContent,
  },
};

function ProfilesDetailHeader() {
  //context hook
  const [controller, dispatch] = useContextController();
  const {selectedDevice} = controller

  const handleClick=(()=>{
    setModal(dispatch, {type: "install", deviceId: selectedDevice.deviceId, eid: selectedDevice.eid, refresh })
  })
  

  const [setNucleusResponse] = useApi()

  async function refresh(){
    const resp = await setNucleusResponse(()=>fetchNucleusResource('devices'))
    setNucleusDevices(dispatch, resp.devices)
  
    const updatedSelectedDevice = resp.devices.filter(elem => {
      return selectedDevice.findIndex(a1elem => a1elem.eid === elem.eid) !== -1;
    });
    setSelectedDevice(dispatch,updatedSelectedDevice)
  }

  return (
    <Header 
      actions ={
        <Button 
          onClick={handleClick}
        >Install profile
        </Button>}
    >
      Profiles 
    </Header >
  );
}

export function ProfilesDetailContent() {

  const [controller] = useContextController();
  const {selectedDevice} = controller
  const sim = selectedDevice ? selectedDevice : null

  const profiles = sim ? sim.profiles ? sim.profiles.map(profile=> {
        return {
          ...profile, // Spread the original object to retain its properties
          deviceStatus: sim.status,
          eid: sim.eid ? sim.eid : null,
          deviceId: sim.deviceId
        }
      }) :[] : []
  const oldProfiles = sim ? sim.oldProfiles ? sim.oldProfiles.map(profile=> {
    return {
      ...profile, // Spread the original object to retain its properties
      deviceStatus: sim.status,
      eid: sim.eid ? sim.eid : null,
      deviceId: sim.deviceId
    }
  }) : [] : []
 
  return( 
    <SpaceBetween direction='vertical' size='l'>
        <EmbeddedTable 
            header = {<Header variant='h4'> Installed Profiles ({sim ? sim.numProfiles ? sim.numProfiles : "N/A" : ""})</Header>}
            resizableColumns={true} 
            items={profiles} 
            columnDefinitions={sim ? sim.isEuicc ? sim.isEuicc === true ? COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE : COLUMN_DEFINITIONS_NON_EUICC_PROFILE_TABLE : COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE : COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE} 
            loading={ sim ? false : true }
            loadingText={"Loading profiles"}
        />
        
        <EmbeddedTable 
        header = {<Header variant='h4'> Other Profiles ({sim ? sim.oldProfiles ? sim.oldProfiles.length ? sim.oldProfiles.length  : sim.oldProfiles.length === 0 ? 0 : "N/A" : "" : ""})</Header>}
        resizableColumns={true} 
        items={oldProfiles} 
        columnDefinitions={COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE} 
        loading={ sim ? false : true }
        loadingText={"Loading profiles"}
    /> 
        
    </SpaceBetween>
  )
}
