/*Nucleus API data formatting functions*/

export function getDevicesCount(data) {
    /*For the time being we return size of current query. Count needs to be implemented in Dynamodb Streams*/
    return data.length
}

export function getProfilesCount(data) {
    /*For the time being we return number of pofiles in current query. Count needs to be implemented in Dynamodb Streams*/
    let totalNumProfiles = 0
    for (let device of data) {
        device.numProfiles ? totalNumProfiles += parseInt(device.numProfiles) : totalNumProfiles += 0
    }
    
    return totalNumProfiles
}

export function getProfileActiveSeries(data) {
    let activeOperators = {}

    for (let device of data) {
        if (device.activeOperator in activeOperators) {
        activeOperators[device.activeOperator] += 1
        } else {
        activeOperators[device.activeOperator] = 1
        }
    }

    const activeSeries = {
        labels: Object.keys(activeOperators),
        datasets: 
        {
            label: "Profiles",
            backgroundColors: ["primary", "info"],
            data: Object.values(activeOperators),
        },
        cutout: '80%',
    }; 

    return activeSeries

}

export function getProfileInstalledSeries(data) {
    let installedOperators = {}

    for (let device of data) {
        for (let profile of device.profiles) {
        if (profile.operator in installedOperators) {
            installedOperators[profile.operator] += 1
        } else {
            installedOperators[profile.operator] = 1
        }
        }
    }

    const installedSeries = {
        labels: Object.keys(installedOperators),
        datasets: 
        {
            label: "Usage",
            backgroundColors: ["warrning", "info", "secondary", "success"],
            data: Object.values(installedOperators),
        },
        cutout: '80%',
    }; 
    return installedSeries  
}

export function cleanDeviceStatus(data) {
    const newProfileStatus = data.profiles.map((element, index) => {
        return {
            "iccid": element.iccid,
            "operator": element.operator,
            "status": element.status.split("#")[1]
        }
    });
    const clean_data = {
        "eid": data.eid,
        "activeOperator": data.activeOperator,
        "lastUpdate": data.lastUpdate,
        "loloId": data.loloId,
        "numProfiles": data.numProfiles,
        "profiles": newProfileStatus,
        "tenantId": data.tenantId
    }
    
    return clean_data
}

export function cleanStatus(data) {
    const clean_data=[]

    for (const device of data) {
        const newProfileStatus = device.profiles.map((element, index) => {
            return {
                "iccid": element.iccid,
                "operator": element.operator,
                "status": element.status.split("#")[1]
            }
        });
        const new_element = {
            "eid": device.eid,
            "activeOperator": device.activeOperator,
            "lastUpdate": device.lastUpdate,
            "loloId": device.loloId,
            "numProfiles": device.numProfiles,
            "profiles": newProfileStatus,
            "tenantId": device.tenantId
        }

        clean_data.push(new_element)

    }
    
    return clean_data
}
  
export function getInstalledProfilesDistribution(operators) {
    const data = operators ? operators.map(item => ({
      title: item['type#key'].split('#')[1],
      value: parseInt(item.installed_enabled) + parseInt(item.installed_disabled)
    })) : []
    const total = data.reduce((acc, item) => acc + item.value, 0);
    return {
        data,
        total
    };
}

export function getInventoryProfilesDistribution(operators) {
    const data = operators ? operators.map(item => ({
      title: item['type#key'].split('#')[1],
      value: parseInt(item.inventory)
    })) : []
    
    const total = data.reduce((acc, item) => acc + item.value, 0);

    return {
        data,
        total
    };
}

export function getActiveProfilesDistribution(operators) {
    const data = operators ? operators.map(item => ({
      title: item['type#key'].split('#')[1],
      value: parseInt(item.installed_enabled)
    })) : []
    
    const total = data.reduce((acc, item) => acc + item.value, 0);

    return {
        data,
        total
    };
}

export function getStatesProfilesDistribution(operators) {
    var properties = ['inventory', 'installed_enabled', 'installed_disabled', 'installing', 'deleted', 'purged', 'total'];

    var result = properties.reduce(function(acc, property) {
        var sum = operators.reduce(function(total, obj) {
          return total + parseInt(obj[property]);
        }, 0);
        
        if (property === 'total') {
            acc.totalSum = sum;
          } else {
            acc[property] = sum;
          }

        return acc;
      }, {});

    var totalSum = result.totalSum;
    delete result.totalSum;

    const transformedArray = Object.entries(result).map(([title, value]) => {
        if ((title !== 'tenantId') && (title !== 'type#key')) {
          return { title, value }
        }   
    });

    return [transformedArray.filter(Boolean), totalSum];
    
}

export function getDDropddownList(operators) {
    const data = operators ? operators.map(item => ({
      text: item['type#key'].split('#')[1],
      id: item['type#key'].split('#')[1],
      disabled: false
    })) : []  

    data.push({text: 'reset', id: 'reset', disabled: false})
    
    return data
}
