// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { useContainerQuery } from '@cloudscape-design/component-toolkit';

// approximate values
const chartMargins = 100;
const filterHeight = 75;
const legendHeight = 60;

export function ResponsiveChartContainer({ render, hideFilter, hideLegend }) {
  const [availableHeight, ref] = useContainerQuery(rect => rect.borderBoxHeight);
  return (
    <div ref={ref} style={{postion: "relative", minHeight: "210px", height : "100%"}}>
      <div>
        {availableHeight
          ? render(availableHeight - chartMargins - (hideLegend ? 0 : legendHeight) - (hideFilter ? 0 : filterHeight))
          : null}
      </div>
    </div>
  );
}