
import Form from "@cloudscape-design/components/form";
import Box from "@cloudscape-design/components/box";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Grid from "@cloudscape-design/components/grid";

import { Fragment } from "react";

function SignInFormFields ({items, state, setState}) {
 
  return  items.map(item => (
    // Without the `key`, React will fire a key warning
    <Fragment key={item.id}>
      <Box margin={{bottom: "l"}}>
      <FormField 
        label={item.label}  
        >
        <Grid
      gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
    >
          <Input 
          placeholder= {state[item.focusId] ? "" : item.label} 
          onFocus={()=>{
            const newState = { ...state };
            newState[item.focusId] = true;
            newState[item.validatedId] = false;
            setState(newState)}} 
          onBlur={()=>{
            const newState = { ...state };
            newState[item.focusId] = false;
            setState(newState)}} 
          onChange={({ detail }) => {
            const newState = { ...state };
            newState[item.id] = detail.value
            setState(newState)}}
          value={state[item.id]}
          type={item.id.includes("Password") ? state[item.seeId] ? "text" : "password" : "text"}
          />
          {item.id.includes("Password") ? <Button 
            iconName={state[item.seeId] ? "angle-left-double" : "angle-right-double"}
            variant="icon" 
            onClick={()=>{
              console.log(item.id)
              console.log(item.id.includes("Password"))

              const newState = { ...state };
              newState[item.seeId] = !state[item.seeId];
              setState(newState)}} 
            /> : <></>}
          
          </Grid>
      </FormField>
      </Box>
    </Fragment>
    ))
 
  
  
}


export default function SignInForm ({ header, description, items, state, setState }) {

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form 
        variant="embedded"
        header={<Header variant="h1" description={description}>{header}</Header>}
      >
        <Container>
            <SignInFormFields items = {items} state={state} setState={setState} />
        </Container>
      </Form>
    </form>
  );
}
