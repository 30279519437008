// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import Header from '@cloudscape-design/components/header';
import BoardItem from '@cloudscape-design/board-components/board-item';
import Box from '@cloudscape-design/components/box';
import { DashboardEmptyState } from '../../emptyState/dashboardEmptyState';
import { boardItemI18nStrings } from '../../i18n-strings.js';
import * as icons from '../../icons/icons';

function PaletteIcon({ iconName }) {
  const icon = icons[iconName];
  const altText = `${iconName} icon`;

  return (
    <>
      <img className="awsui-util-hide-in-dark-mode" src={icon.light} alt={altText} />
      <img className="awsui-util-show-in-dark-mode" src={icon.dark} alt={altText} />
    </>
  );
}

export default function PaletteItem({ title, iconName, description, showPreview }) {
  return (
    <BoardItem header={<Header headingTagOverride="h3">{title}</Header>} i18nStrings={boardItemI18nStrings}>
      {showPreview ? (
        <DashboardEmptyState
          title={title}
          description={description}
          icon={<PaletteIcon iconName={iconName} />}
          verticalCenter={true}
        />
      ) : (
        <div>
          <div>
            <PaletteIcon iconName={iconName} />
          </div>
          <Box variant="p">{description}</Box>
        </div>
      )}
    </BoardItem>
  );
}