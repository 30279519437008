
import React from 'react';
import { Box, SpaceBetween } from '@cloudscape-design/components';

export const DashboardEmptyState = ({ icon, title, description, action, verticalCenter }) => (
  <div>
    <Box margin={{ vertical: 'xxxl' }} textAlign="center" color="text-body-secondary">
      <SpaceBetween size="xxs">
        <div>
          {icon && <div>{icon}</div>}
          <Box variant="strong" color="inherit">
            {title}
          </Box>
          <Box variant="p" color="inherit">
            {description}
          </Box>
        </div>
        {action}
      </SpaceBetween>
    </Box>
  </div>
);