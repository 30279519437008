  import { timestampToDate } from '../../../../utils/utils';
import { StatusIndicator } from '@cloudscape-design/components';

export const COLUMN_DEFINITIONS_NOTIFICATIONS = [
  {
    id: 'timestamp',
    header: 'Date',
    cell: (item) => timestampToDate(item.timestamp),
    width: 110,
    sortingField: 'timestamp',
  },
  {
    id: 'command',
    header: 'Command',
    cell: (item) => item.command,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => <StatusIndicator type={item.status === 'executed' ? 'success' : 'pending'}> {item.status} </StatusIndicator>,
    width: 130,
  },
  {
    id: 'code',
    header: 'Code',
    cell: (item) => <StatusIndicator type={item.code ? item.code === 200 ? 'success' : 'error' : ''}> {item.code} </StatusIndicator>,
    width: 130,
      
  },
  {
    id: 'result',
    header: 'Result',
    cell: (item) => item.result ? item.result : "N/A",
    width: 200,
      
  },
  {
    id: 'iccid',
    header: 'Iccid',
    cell: (item) => item.iccid ? item.iccid : "N/A",
  },
  {
    id: 'eid',
    header: 'Eid',
    cell: (item) => item['eid#ts'].split('#')[0] ? item['eid#ts'].split('#')[0] : "N/A",
  },
  {
    id: 'owner',
    header: 'User id',
    cell: (item) => item['ownerId#ts'].split('#')[0].split('-')[0] ? item['ownerId#ts'].split('#')[0].split('-')[0] : "N/A",
  },
];


