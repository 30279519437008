// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { useEffect, useState } from 'react';
import HelpPanel from '@cloudscape-design/components/help-panel';
import { useCollection } from '@cloudscape-design/collection-hooks';
import Button from '@cloudscape-design/components/button';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import ButtonDropdown from '@cloudscape-design/components/button-dropdown';
import Icon from '@cloudscape-design/components/icon';

import Breadcrumbs from '../../components/breadcrumbs';
import Navigation from '../../components/navigation';
import SplitShellLayout from '../../layouts/splitShell';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import GenericTableP from '../../components/genericTable/genericTableP';
import CustomModal from '../../components/modals';
import { Notifications } from '../../components/notifications/notifications';
import { COLUMN_DEFINITIONS_MAIN } from './utils/tableConfig';

import { I18nStringsTables } from '../../i18-strings/tables';
import { I18nStringsButtons } from '../../i18-strings/buttons';

import { useApi } from '../../hooks/useApi';
import {fetchNucleusResource} from '../../api/nucleus'

import { FILTERING_PROPERTIES } from './utils/filteringProperties';
import { DEFAULT_PREFERENCES } from './utils/defaults';

import {
  useContextController,
  setModal,
  setNucleusDevices
} from "../../context/index";

export default function DevicesPage() {

  const [nucleusResponse, setNucleusResponse] = useApi()
  const [preferences, setPreferences] = useLocalStorage('React-Devices-Preferences',DEFAULT_PREFERENCES);

  const [selectedDevicesCache, setSelectedDevicesCache] = useState([])

  //context hook
  const [controller, dispatch] = useContextController();
  const {nucleusDevices} = controller

  const handleDropdownClick=((id)=>{
      setModal(dispatch, {type: id, id: selectedDevicesCache[0].eid, refresh })
  })

  async function refresh(){
    /*await setLoloResponse(()=>fetchLoloResource('stats')))*/
    const resp = await setNucleusResponse(()=>fetchNucleusResource('devices'))
    setNucleusDevices(dispatch, resp.devices)
  
    const updatedSelectedDevices = resp.devices.filter(elem => {
      return selectedDevicesCache.findIndex(a1elem => a1elem.eid === elem.eid) !== -1;
    });
    setSelectedDevicesCache(updatedSelectedDevices)
  }

  useEffect(() => {
    if(!nucleusDevices){
      refresh()
    }
  
  },[]);// eslint-disable-line react-hooks/exhaustive-deps

  const EmptyState = ({ title, subtitle, action }) => {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  };

  const tableActions = ( 
    <SpaceBetween size="xs" direction="horizontal">
   
    {/* <Button href="/create-flavor/index.html" variant="primary">
      {I18nStringsButtons.create}
    </Button> */}
    <ButtonDropdown 
      disabled={selectedDevicesCache?.length === 0}
      items={[
        { text: I18nStringsButtons.installProfile, id: "install", disabled: selectedDevicesCache?.length === 1 ? false : true },
        /*{ text: I18nStringsButtons.deactivate, id: "deactivateDevice", disabled: selectedDevicesCache?.length === 1 ? false : true },
        { text: I18nStringsButtons.delete, id: "deleteDevice", disabled: selectedDevicesCache?.length === 1 ? false : true },
        { text: I18nStringsButtons.createCampaign, id: "campaign", disabled: selectedDevicesCache?.length > 1 ? false : true },*/
      ]}
      onItemClick={({detail})=>{handleDropdownClick(detail.id)}} 
      expandToViewport = {true}

    >
    {I18nStringsButtons.actions}
    </ButtonDropdown>
    <Button onClick={refresh} loading={nucleusResponse.isFetching} ><Icon name="refresh"/></Button>
  </SpaceBetween>) 
  
  const { items, propertyFilterProps, actions, filteredItemsCount, paginationProps, collectionProps } = useCollection(
    nucleusDevices ? nucleusDevices : [],
    {
      pagination: { pageSize: preferences?.pageSize },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS_MAIN[0] } },
      selection: { },
      propertyFiltering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setPropertyFiltering({ tokens: [], operation: 'and' })}>Clear filter</Button>}
          />
        ),
        empty: (
          <EmptyState title="No profiles" subtitle="No profiles to display." action={<Button>Create profile</Button>} />
        ),
        filteringProperties: FILTERING_PROPERTIES
      }
    }
  );
    
  return (
    <SplitShellLayout
      contentType="table"
      breadcrumbs={<Breadcrumbs active={[{ text: 'Devices', href: '/devices/index.html' }]} />}
      navigation={<Navigation />}
      notifications={<Notifications/>}
      tools={<HelpPanel header={<h2>Help panel</h2>}></HelpPanel>}
      collectionPropsSelectedItems = {selectedDevicesCache}
      refresh={refresh}
    >
      <GenericTableP 
        items={items} 
        filterProps={propertyFilterProps} 
        actions={tableActions} 
        filteredItemsCount={filteredItemsCount} 
        paginationProps={paginationProps} 
        collectionProps={collectionProps} 
        preferences={preferences} 
        setPreferences={setPreferences} 
        isFetching={(nucleusResponse.isFetching && !nucleusDevices)} 
        loadingText="Loading devices"
        refresh={refresh} 
        buttonLoading={nucleusResponse.isFetching} 
        onSelectionChange={({detail})=>{setSelectedDevicesCache(detail.selectedItems)}}
        selectedItems={selectedDevicesCache}
        columns={COLUMN_DEFINITIONS_MAIN}
        headerText={I18nStringsTables.devicesHeader}
        findTextPlaceholder={I18nStringsTables.devicesFind}
        variant="full-page"
        id="eid"
        />
        <CustomModal/>
    </SplitShellLayout>
  );
}
