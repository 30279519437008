
import React from 'react';
import { useEffect, useState } from 'react';

import { useCollection } from '@cloudscape-design/collection-hooks';
import HelpPanel from '@cloudscape-design/components/help-panel';
import Button from '@cloudscape-design/components/button';
import SpaceBetween from '@cloudscape-design/components/space-between';
import ButtonDropdown from '@cloudscape-design/components/button-dropdown';

import Breadcrumbs from '../../components/breadcrumbs';
import Navigation from '../../components/navigation';
import ShellLayout from '../../layouts/shell';
import { Notifications } from '../../components/notifications/notifications';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import EmptyState from '../../components/genericTable/emptyState';
import Icon from '@cloudscape-design/components/icon';
import CustomModal from '../../components/modals';
import GenericTableP from '../../components/genericTable/genericTableP';
import { I18nStringsTables } from '../../i18-strings/tables';
import { I18nStringsButtons } from '../../i18-strings/buttons';
import { DEFAULT_PREFERENCES } from './utils/defaults';
import { COLUMN_DEFINITIONS_MAIN } from './utils/tableConfig';

import {fetchNucleusResource} from '../../api/nucleus'
import { useApi } from '../../hooks/useApi';

import {
  useContextController,
  setModal,
  setOperators
} from "../../context/index";

import { FILTERING_PROPERTIES } from './utils/filteringProperties';

export default function SKUPage() {

  const [nucleusResponse, setNucleusResponse] = useApi()
  const [preferences, setPreferences] = useLocalStorage('React-Cards-Preferences', DEFAULT_PREFERENCES);
  const [selectedCardsCache, setSelectedCardsCache] = useState([])

  //context hook
  const [controller, dispatch] = useContextController();
  const { operators } = controller;

  const handleCreate=((id)=>{
    setModal(dispatch, {type: id, refresh: refresh})
  })

  const handleDropdownClick=((id)=>{
    setModal(dispatch, {type: id, refresh:refresh, id: selectedCardsCache[0]['type#key'].split('#')[1]})
  })

  async function refresh() {
    const resp = await setNucleusResponse(()=>fetchNucleusResource('skus'))
    await setOperators(dispatch, resp.skus)
    const updatedSelectedCards = resp.skus.filter(elem => {
      return selectedCardsCache.findIndex(a1elem => a1elem['type#key'] === elem['type#key']) !== -1;
    });
    setSelectedCardsCache(updatedSelectedCards)
  }

  useEffect(() => {
    
    if(!operators){
      refresh()
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const tableActions = ( 
    <SpaceBetween size="xs" direction="horizontal">
    <ButtonDropdown 
      disabled={selectedCardsCache?.length === 0}
      items={[
        { text: I18nStringsButtons.delete, id: "deleteOperator", disabled: selectedCardsCache?.length === 1 ? false : true },
      ]}
      onItemClick={({detail})=>{handleDropdownClick(detail.id)}}
    >
    {I18nStringsButtons.actions}
    </ButtonDropdown>
    <Button onClick={()=>handleCreate("createOperator")} variant="primary">
      {I18nStringsButtons.create}
    </Button>
    <Button onClick={refresh} loading={nucleusResponse.isFetching} ><Icon name="refresh"/></Button>
  </SpaceBetween>) 

  
  
  const { items, propertyFilterProps, actions, filteredItemsCount, paginationProps, collectionProps } = useCollection(
    operators ? operators : [],
    {
      pagination: { pageSize: preferences?.pageSize },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS_MAIN[0] } },
      selection: { },
      propertyFiltering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setPropertyFiltering({ tokens: [], operation: 'and' })}>Clear filter</Button>}
          />
        ),
        empty: (
          <EmptyState title="No profiles" subtitle="No profiles to display." action={<Button>Create profile</Button>} />
        ),
        filteringProperties: FILTERING_PROPERTIES
      }
    }
  );
    
  return (
    <ShellLayout
      contentType="table"
      breadcrumbs={<Breadcrumbs active={[{ text: 'SKUs', href: '/skus' }]} />}
      navigation={<Navigation />}
      notifications={<Notifications/>}
      tools={<HelpPanel header={<h2>Help panel</h2>}></HelpPanel>}
    >
      <GenericTableP 
                items={items} 
                filterProps={propertyFilterProps} 
                actions={tableActions} 
                filteredItemsCount={filteredItemsCount} 
                paginationProps={paginationProps} 
                collectionProps={collectionProps} 
                preferences={preferences} 
                setPreferences={setPreferences} 
                isFetching={(nucleusResponse.isFetching && (!operators))} 
                loadingText={"Loading skus"}
                refresh={refresh} 
                buttonLoading={nucleusResponse.isFetching} 
                onSelectionChange={({detail})=>{setSelectedCardsCache(detail.selectedItems)}}
                selectedItems={selectedCardsCache}
                columns={COLUMN_DEFINITIONS_MAIN}
                headerText={I18nStringsTables.skuHeader}
                findTextPlaceholder={I18nStringsTables.skuFind}
                variant="full-page"
                id="type#key"
                /> 
      <CustomModal/>
    </ShellLayout>
  );
}
