

import DevicesModalDelete from "./devicesDeleteModal";
import OperatorModalCreate from "./operatorModalCreate";
import ProfilesBulkUpload from "./profilesBulkUpload";
import ConfirmDeleteModal from "./confirmDeleteModal";
import DevicesInstallProfileModal from "./devicesInstallProfileModal";
import ProfilesModalCreate from "./profilesCreate";


export default function ModalSelection({modal, handleDismiss, handleAction}){
  if (modal===null){
    return
  }
  switch(modal.type){
    case 'deleteDevice':
      return <DevicesModalDelete  modal={modal} handleDismiss={handleDismiss} handleAction={handleAction}/>
    case 'install':
      return <DevicesInstallProfileModal  modal={modal} handleDismiss={handleDismiss} handleAction={handleAction}/>
    case 'bulkUpload':
      return <ProfilesBulkUpload modal={modal} handleDismiss={handleDismiss}/>
    case 'createProfile':
      return <ProfilesModalCreate handleDismiss={handleDismiss} handleAction={handleAction}/>
    case 'createOperator':
      return <OperatorModalCreate handleDismiss={handleDismiss} handleAction={handleAction}/>
    case 'deleteOperator':
      return <ConfirmDeleteModal modal={modal} handleDismiss={handleDismiss} handleAction={handleAction} />
    default:
      return
  }
  
  


}