
import { createContext, useContext, useReducer, useMemo } from "react";

// The main context
const Context = createContext(null);

// Setting custom name for the context which is visible on react dev tools
Context.displayName = "NucleusContext";

//reducer
function reducer(state, action) {
  switch (action.type) {
    case "COGNITO_USER": {
      return { ...state, cognitoUser: action.value };
    }
    case "USER": {
      return { ...state, user: action.value };
    }
    case "NOTIFICATIONS": {
      return { ...state, notifications: action.value };
    }
    case "MODAL": {
      return { ...state, modal: action.value };
    }
    case "OPERATORS": {
      return { ...state, operators: action.value };
    }
    case "NUCLEUS_DEVICES": {
      return { ...state, nucleusDevices: action.value };
    }
    case "NUCLEUS_DEVICE_NOTIFICATIONS": {
      return { ...state, nucleusDeviceNotifications: action.value };
    }
    case "NUCLEUS_PROFILES": {
      return { ...state, nucleusProfiles: action.value };
    }
    case "ACTIVE_HREF": {
      return { ...state, activeHref: action.value };
    }
    case "SELECTED_DEVICE": {
      return { ...state, selectedDevice: action.value };
    }
    case "SELECTED_SIM_EID": {
      return { ...state, selectedSIMEid: action.value };
    }
    case "SELECTED_SIM_IS_EUICC": {
      return { ...state, selectedSIMIsEuicc: action.value };
    }
    case "DEVICES_COUNT": {
      return { ...state, devicesCount: action.value };
    }
    case "IS_FETCHING": {
      return { ...state, isFetching: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Context provider
function ContextControllerProvider({ children }) {
  const initialState = {
    user: null,
    cognitoUser: null,
    notifications: [],
    modal: null,
    operators: null,
    activeHref: window.location.pathname === "/devices/device-detail" ? "/devices" : window.location.pathname,
    nucleusDevices: null,
    nucleusDeviceNotifications: null,
    nucleusProfiles: null,
    selectedDevice: false,
    selectedSIMEid: null,
    selectedSIMIsEuicc: false,
    devicesCount: null,
    isFetching: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

// Custom hook for using context
function useContextController() {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useContextController should be used inside the ContextControllerProvider.");
  }

  return context;
}

// Context module functions
const setCognitoUser = (dispatch, value) => dispatch({ type: "COGNITO_USER", value });
const setUser = (dispatch, value) => dispatch({ type: "USER", value });
const setNotifications = (dispatch, value) => dispatch({ type: "NOTIFICATIONS", value });
const setModal = (dispatch, value) => dispatch({ type: "MODAL", value });
const setOperators = (dispatch, value) => dispatch({ type: "OPERATORS", value });
const setActiveHref = (dispatch, value) => dispatch({ type: "ACTIVE_HREF", value });
const setNucleusDevices = (dispatch, value) => dispatch({ type: "NUCLEUS_DEVICES", value });
const setNucleusDeviceNotifications = (dispatch, value) => dispatch({ type: "NUCLEUS_DEVICE_NOTIFICATIONS", value });
const setNucleusProfiles = (dispatch, value) => dispatch({ type: "NUCLEUS_PROFILES", value });
const setSelectedDevice = (dispatch, value) => dispatch({ type: "SELECTED_DEVICE", value });
const setSelectedSIMEid = (dispatch, value) => dispatch({ type: "SELECTED_SIM_EID", value });
const setSelectedSIMIsEuicc = (dispatch, value) => dispatch({ type: "SELECTED_SIM_IS_EUICC", value });
const setDevicesCount = (dispatch, value) => dispatch({ type: "DEVICES_COUNT", value });
const setIsFetching = (dispatch, value) => dispatch({ type: "IS_FETCHING", value });

export {
  ContextControllerProvider,
  useContextController,
  setUser,
  setCognitoUser,
  setNotifications,
  setModal,
  setOperators,
  setActiveHref,
  setNucleusDevices,
  setNucleusDeviceNotifications,
  setNucleusProfiles,
  setSelectedDevice,
  setSelectedSIMEid,
  setSelectedSIMIsEuicc,
  setDevicesCount,
  setIsFetching
};
