// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Link } from '@cloudscape-design/components';


export const CounterLink = ({ children }) => {
  return (
    <Link variant="awsui-value-large" href="#">
      {children}
    </Link>
  );
};

export const CounterLinkSmall = ({ children }) => {
  return (
    <Link variant="secondary" fontSize='heading-m'>
      {children}
    </Link>
  );
};

export const CounterLinkVerySmall = ({ children }) => {
  return (
    <Link variant="secondary" fontSize='heading-xs'>
      {children}
    </Link>
  );
};