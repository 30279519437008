import { notificationsI18nStrings } from '../i18-strings/notifications';
import { interpolate } from './utils';
import { createAxiosErrorNotification } from './createAxiosErrorNotification';

export function createErrorNotification(error, eid=null, command=null) {
  if (error === "No current user") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.NotCurrentUser}`,
      }
    return notification
  }
  if (error.name === "UserNotFoundException") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.userNotFound}`,
      }
    return notification
  }
  else if (error.name === "NotAuthorizedException") {
      const notification = {
          type: 'error',
          message: `${notificationsI18nStrings.wrongPassword}`,
        }
      return notification
  }   
  else if (error.name === "SIM_Error") {
    const notification = {
        type: 'error',
        message: interpolate(notificationsI18nStrings.simError, {error: error.description}),
      }
    return notification
  }
  else if (error.name === "timeout") {
    const notification = {
        type: 'info',
        message: interpolate(notificationsI18nStrings.timeout,{command: command, eid: eid}),
      }
    return notification
  }
  else  if (error.name === "PasswordLengthError") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.passworrdLength}`,
      }
    return notification
  }
  else  if (error.name === "EmptyPasswordError") {   
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.emptyPasword}`,
      }
    return notification 
  }
  else if (error.name === "UppercasePasswordError") { 
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.uppercasePassword}`,
      }
    return notification
  }
  else if (error.name === "LowercasePasswordError") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.lowercasePassword}`,
      }
    return notification
  }
  else if (error.name === "DigitsPasswordError") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.digitsPassword}`,
      }
    return notification
  }
  else if (error.name === "SpecialCharPasswordError") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.specialCharPassword}`,
      }
    return notification
  }
  else if (error.name === "PasswordDoNotMatchError") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.passwordDoNotMatch}`,
      }
    return notification
  }
  else if (error.name === "PasswordResetError") {
    const notification = {
        type: 'error',
        message: `${notificationsI18nStrings.passwordResetError}`,
      }
    return notification
  }
  else if (error.name === "AxiosError") {
    return createAxiosErrorNotification(error, command, eid)
  }
  else {
      const notification = {
          type: 'error',
          message: `${notificationsI18nStrings.default}`,
        }
      return notification

  }
    
    
}