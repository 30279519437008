
import { useState } from 'react';
import { load, save } from '../utils/utils';

export function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => load(key) ?? defaultValue);

  function handleValueChange(newValue) {
    setValue(newValue);
    save(key, newValue);
  }

  return [value, handleValueChange];
}