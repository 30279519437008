import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormField, SpaceBetween } from "@cloudscape-design/components";
import Select from "@cloudscape-design/components/select";

import FileEntry from "./fileEntry";
import Token from "./token";

//Context
import {
  useContextController,
  setActiveHref,
  setOperators,
} from "../../context/index.js";

import {fetchNucleusResource} from '../../api/nucleus'
import { useApi } from '../../hooks/useApi';

export default function FileUploader({
  fileInputId,
  text,
  label,
  description,
  constraintText,
  errorText,
  setErrorText,
  files,
  onFilesUploaded,
  onFileRemoved,
  multiple = false,
  i18nStrings,
  selectedOption,
  setSelectedOption,
  handledismiss
}) {
  //context hook
  const [controller, dispatch] = useContextController();
  const { operators } = controller;

  const navigate = useNavigate();

  const [nucleusResponse, setNucleusResponse] = useApi()

  const refresh = useCallback (() => async () => {
    const resp = await setNucleusResponse(()=>fetchNucleusResource('operators'))
    await setOperators(dispatch, resp.operators)
  },[dispatch, setNucleusResponse])

  useEffect(() => {
    if(!operators){
      refresh()
    }
  }, [operators, refresh]);

  const dropdownItems = (()=>{
    const items = [{
      label: 'create new tag',
      value: 'createNew',
    }];
    for ( let i in operators) {
      const value = operators[i]['type#key'].split('#')[1]
      items.push({
        label: value,
        value: value,
      });
    }
    return items;  
  })

  return (
    <>
      <FormField
        label={label}
        description={description}
        constraintText={constraintText}
        errorText={errorText}
      >
        <SpaceBetween direction="horizontal" size="l">
        <Button
          iconName="upload"
          onClick={() => {
            document.getElementById(fileInputId).click();
          }}
        >
          <input
            id={fileInputId}
            type="file"
            multiple={multiple}
            hidden
            onChange={() => {
              const fileInput = document.getElementById(fileInputId);
              onFilesUploaded(
                Array.from((fileInput).files)
              );
            }}
          />
          {text}
        </Button>
        <Select
          selectedOption={selectedOption}
          onChange={({ detail }) => {
            setSelectedOption(detail.selectedOption)
            setErrorText(null)
            if (detail.selectedOption.value === 'createNew') {
              setActiveHref(dispatch, '/tags/operators')
              navigate('/tags/operators')
              handledismiss()
            }
          }}
          options={dropdownItems()}
          expandToViewport={true}
          placeholder="Select Operator"
          statusType={nucleusResponse.isFetching ? "loading" : nucleusResponse.error ? "error" : "success"}
        />
        </SpaceBetween>
      </FormField>
      <Box margin={{ top: "l" }}>
        {files &&
          files.length > 0 &&
          (multiple ? (
            Array.from(files).map((file, fileIdx) => (
              <div key={`file-${fileIdx}`} data-testid={`token-${file.name}`}>
                <Token
                  onClose={
                    onFileRemoved
                      ? () => {
                          onFileRemoved(fileIdx);
                        }
                      : null
                  }
                >
                  <FileEntry file={file} showImage i18nStrings={i18nStrings} />
                </Token>
              </div>
            ))
          ) : (
            <FileEntry file={files[0]} i18nStrings={i18nStrings} />
          ))}
      </Box>
    </>
  );
}