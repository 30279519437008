import React from 'react';
import { Link, StatusIndicator } from '@cloudscape-design/components';
import { timestampToDate } from '../../../utils/utils';

import TableLink from '../../../components/tableLink';

export const COLUMN_DEFINITIONS_MAIN = [
    {
      id: 'iccid',
      header: 'Iccid',
      cell: (item) => <Link href="#">{item.iccid}</Link>,
      sortingField: 'iccid',
      width: 200, 
    },
    {
      id: 'state',
      header: 'Profile state',
      cell: (item) => (
        <>
          <StatusIndicator type={
            item['status#ts'].includes('inventory') ? 'info' : 
            item['status#ts'].includes('enabled') ? 'success' : 
            item['status#ts'].includes('disabled') ? 'stopped' : 
            item['status#ts'].includes('deleted') ? 'warning' : 
            item['status#ts'].includes('purged') ? 'error' : 
            'in-progress'}> { item['status#ts'].split('#')[0] } </StatusIndicator>
        </>
      ),
      sortingField: 'status#ts',
      width: 190,
       
    },
    {
      id: 'sku',
      header: 'SKU',
      cell: (item) => (item['sku#status#ts'].split('#')[0]),
      sortingField: 'sku#status#ts',
      width: 150,
       
    },
    {
      id: 'lastUpdate',
      header: 'Last update',
      cell: (item) => (timestampToDate(item['status#ts'].split('#').length === 3 ? item['status#ts'].split('#')[2] : item['status#ts'].split('#')[1])),
      sortingField: 'status#ts',
      width: 200,
       
    },
    {
      id: 'eid',
      header: 'eid',
      cell: (item) => <TableLink item={item}/>,
      sortingField: 'eid',
      width: 300,
       
    },
    {
      id: 'activacionCode',
      header: 'Activation Code',
      cell: (item) => item.activationCode,
      sortingField: 'activationCode',
      width: 250,
       
    },
    {
      id: 'provisionDate',
      header: 'Provision Date',
      cell: (item) => (timestampToDate(item.provisionDate)),
      sortingField: 'provisionDate',
      width: 200,
    },
  ];