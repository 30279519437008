
 

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import Table from '@cloudscape-design/components/table';
import React from 'react';

// workaround component, because embedded table is not available in Cloudscape classic design
export function EmbeddedTable(props) {
  return (
    <div>
      <Table variant="embedded" {...props} />
    </div>
  );
}