
import axios from 'axios';
import {Auth} from 'aws-amplify'
import { createSuccessNotification } from '../../utils/createSuccessNotification';
import { createErrorNotification } from '../../utils/createErrorNotification';
import { v4 as uuidv4 } from "uuid";

const POLLING_INTERVAL = 10000 //ms
const POLLING_TIMEOUT = 300000 //ms

export async function PollNotification(messageId, notificationCallback, eid=null, command=null){

    const api = process.env.REACT_APP_ENV_API_URL + `/notifications/${messageId}`;
    const session = await Auth.currentSession();
    const nucleus_headers = {headers: {'Authorization': `Bearer ${session.getIdToken().getJwtToken()}` }};

    const notification = {}
    
    const interval = setInterval(() => {
        axios
        .get(api, nucleus_headers)
        .then((response) => { 
            const not = response.data.notification
            if (response.data.notification.hasOwnProperty('code')){
                if (not.code === 200) {
                    notification[uuidv4()] = createSuccessNotification("success", eid, command, not.result)
                    notificationCallback(notification)
                }
                else{  
                    notification[uuidv4()] = createErrorNotification('genericError')
                    notificationCallback(notification)
                }
                clearInterval(interval) 
                clearTimeout(timer)
            }
        })
        .catch((error) => {
            if (error.response ? error.response.status !== 404 : false) {
                notification[uuidv4()] = createErrorNotification(error, eid, command)
                notificationCallback(notification)
                clearInterval(interval) 
                clearTimeout(timer)  
            } 
            //Si es 404 seguimos haciendo polling hasta el timeout, o hasta que recuperemos la notificacion 
        });
    }, POLLING_INTERVAL); 
    let timer = setTimeout(function( ) { 
        const customError = {
            name: "timeout",
            description: "polling timeout"
        }
        notification[uuidv4()] = createErrorNotification(customError, eid, command)
        notificationCallback(notification)
        clearInterval( interval ); 
    }, POLLING_TIMEOUT);
}