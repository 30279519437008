import { useState, useEffect } from "react";

import {TextContent as Text} from "@cloudscape-design/components";
import Modal from "@cloudscape-design/components/modal";
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Box from "@cloudscape-design/components/box";
import Select from "@cloudscape-design/components/select";
import FormField from "@cloudscape-design/components/form-field";

import { I18nStringsModals } from "../../i18-strings/modals";
import { I18nStringsButtons } from "../../i18-strings/buttons";

//Context
import {
  useContextController,
} from "../../context/index.js";




export default function DevicesInstallProfileModal ({handleDismiss, handleAction, modal, monitored=true}) {

  //context hook
  const [controller, dispatch] = useContextController();
  const { operators, isFetching } = controller;

  const [options, setOptions] = useState([])
  const [errorText, setErrorText] = useState(null)

  useEffect(() => {
    var operatorsMenu = []
    if(operators || operators != null){ 
      operatorsMenu = operators.reduce((result, operator)=>{
        if (operator['inventory'] > 0) {   
          result.push({label: operator['type#key'].split('#')[1], value: operator['type#key'].split('#')[1]})
        }
        return result     
      },[])
    }
    setOptions(operatorsMenu)
    
  }, [operators]);// eslint-disable-line react-hooks/exhaustive-deps

  const [
    selectedOption,
    setSelectedOption
  ] = useState(null);

  return (
    <Modal
      onDismiss={handleDismiss}
      visible={true}
      footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
        <Button variant="link" onClick={handleDismiss}>{I18nStringsButtons.cancel}</Button>
        <Button 
          variant="primary" 
          onClick={()=> {
            if (selectedOption) {
              handleAction("installProfile", selectedOption.value)
            }
            else {
              setErrorText(I18nStringsModals.chooseOperatorError)
            }
          }}
        >
          {I18nStringsButtons.install}
        </Button>
        </SpaceBetween>
        </Box>
        }
      header={I18nStringsModals.installProfileHeader}
    >
      
      <SpaceBetween direction="vertical" size="s">
         <Text>{I18nStringsModals.installProfileDescription}</Text>
         <FormField errorText={errorText}>
         <Select
            selectedOption={selectedOption}
            onChange={({ detail }) =>{
              setSelectedOption(detail.selectedOption)
              setErrorText(null)
            }}
            options={options}
            errorText={I18nStringsModals.errorFetchingOperators}
            placeholder={I18nStringsModals.chooseOperatorPlaceholder}
            statusType={isFetching ? "loading" : ((operators  === null) ? "loading" : ((operators.length === options.length) ? "success" : (options.length === 0 ? "loading" : "success")))}
            empty={I18nStringsModals.chooseOperatorEmpty}
        />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
}