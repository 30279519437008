import { notificationsI18nStrings } from '../i18-strings/notifications';
import { interpolate } from './utils';

export function createAxiosErrorNotification(error, eid=null, command=null) {

  if (error.code === 'ERR_NETWORK') {
    const notification = {
      type: 'error',
      message: notificationsI18nStrings.default,
    }
    return notification
  }
  
  if (error.response.status === 409) {
    if (error.response.data.message.includes('Device is busy')) {
      const tmp = error.response.data.message.split(" ")
      const c = tmp[tmp.length - 1];
      const notification = {
        type: 'error',
        message: interpolate(notificationsI18nStrings.deviceBusy, {command: c})
      }
      return notification
    }
    else {
    const notification = {
        type: 'error',
        message: error.response.data.message,
      }
      return notification
    }
    
  }
  if (error.response.status === 404) {
    if (error.response.data.message) {
      const notification = {
        type: 'error',
        message: error.response.data.message
      }
      return notification
    }
    else {
      const notification = {
        type: 'error',
        message: notificationsI18nStrings.notFound,
    }
    return notification
  }
    
  }
  if (error.response.status === 400) {
    if (error.response.data.message) {
      const notification = {
        type: 'error',
        message: error.response.data.message
      }
      return notification
    }
  }
  else {
      const notification = {
          type: 'error',
          message: `${notificationsI18nStrings.default}`,
        }
      return notification
  }    
}