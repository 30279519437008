// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import BreadcrumbGroup from '@cloudscape-design/components/breadcrumb-group';

const items = [{ text: 'KDDI', href: '/home/index.html' }];

export default function Breadcrumbs({ active }) {
  return <BreadcrumbGroup items={[...items, ...active]} />;
}
