import React from "react";
import { Button, SpaceBetween } from "@cloudscape-design/components";
import Container from "@cloudscape-design/components/container";

export default function Token({children, onClose}) {
  return (
    <Container>
      <div style={{ width: "100%" }}> {children} </div>
      {!!onClose && (
        <SpaceBetween size="s">
          <Button
            iconName="close"
            variant="inline-icon"
            onClick={onClose}
            ariaLabel="close-token"
          />
        </SpaceBetween>
      )}
    </Container>
  );
}