/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import { I18nProvider } from '@cloudscape-design/components/i18n';

// Import all locales
import messages from '@cloudscape-design/components/i18n/messages/all.all';

//Context provider
import { ContextControllerProvider } from "./context/index.js"
//AWS Amplify with Auth
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports'; 
Amplify.configure(awsExports);
Auth.configure(awsExports);

const container = document.getElementById("root");
const root = createRoot(container);

const supported_locales = ["ar", "en", "en-GB", "de", "es", "fr", "ja", "it", "pt-BR", "ko", "zh-CN", "zh-TW", "id", "tr"];
const userLanguages = navigator.languages || [navigator.language];
const matchedLocale = userLanguages.find(lang => supported_locales.includes(lang)) || "en";

console.log(matchedLocale);

root.render(
  <BrowserRouter>
    <ContextControllerProvider>
      <I18nProvider locale = {matchedLocale} messages={[messages]}>
        <App />
      </I18nProvider>
    </ContextControllerProvider>
  </BrowserRouter>
);
