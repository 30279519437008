import React from "react";

import DevicesPage from "./pages/devices";
import ProfilesPage from "./pages/profiles";
import OperatorsTag from "./pages/sku";
import DeviceDetail from "./pages/deviceDetail";
import Dashboard from "./pages/dashboard";
import PasswordReset from "./pages/authentication/passwordReset";

const routes = [
  {
    type: "route",
    name: "PasswordReset",
    key: "passwordReset",
    route: "/password-reset",
    page: <PasswordReset/>,
  },
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    page: <Dashboard />,
  },
  {
    type: "route",
    name: "Devices",
    key: "devices",
    route: "/devices",
    page: <DevicesPage/>,
  },
  {
    type: "route",
    name: "Profiles",
    key: "profiles",
    route: "/profiles",
    page: <ProfilesPage/>,
  },
  {
    type: "route",
    name: "skus",
    key: "skus",
    route: "/skus",
    page: <OperatorsTag/>,
  },
  {
    type: "route",
    name: "Device detail",
    key: "deviveDetail",
    route: "/devices/device-detail",
    page: <DeviceDetail/>,
  },
];

export default routes;
