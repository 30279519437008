export const I18nStringsButtons = {
    delete: 'Delete',
    operators: 'Operator',
    actions: 'Actions',
    edit: 'Edit',
    create: 'Create',
    cancel: 'Cancel',
    confirm: 'Confirm',
    enable: 'Enable',
    upload: 'Upload',
    deactivate: 'Deactivate',
    createCampaign: 'Create campaign',
    install: 'Install',
    installProfile: 'Install profile',
    bulkCreation: 'Bulk upload',
};