// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Box, Header, ColumnLayout, Spinner } from '@cloudscape-design/components';
import { CounterLink } from '../../../common-components';
import { getInstalledProfilesDistribution, getInventoryProfilesDistribution } from '../../../../utils/NucleusDataFormatter';


import {
  useContextController,
} from "../../../../context/index";


export const fleetData = {
  definition: { defaultRowSpan: 2, defaultColumnSpan: 4 },
  data: {
    icon: 'list',
    title: 'Fleet data',
    description: 'General information about your fleet',
    header: ServiceHealthHeader,
    content: ServiceHealthContent,
  },
};

function ServiceHealthHeader() {
  return (
    <Header variant="h2"  description="General information about your fleet">
      Fleet data
    </Header>
  );
}

function ServiceHealthContent() {
  
  //context hook
  const [controller] = useContextController();
  const {devicesCount, operators} = controller


  return (
    <ColumnLayout columns={4}>
      <div>
        <Box variant="awsui-key-label">Total fleet devices</Box>
        <div><CounterLink>{devicesCount!==null ? devicesCount : <Spinner/>}</CounterLink></div>
      </div>
      <div>
        <Box variant="awsui-key-label">Total installed profiles</Box>
        <div><CounterLink>{operators ? getInstalledProfilesDistribution(operators).total : <Spinner/>}</CounterLink></div>
      </div>
      <div>
        <Box variant="awsui-key-label">Average profiles / device</Box>
        <div><CounterLink>  {devicesCount!==null ? operators ? (getInstalledProfilesDistribution(operators).total / devicesCount).toFixed(2) : <Spinner/> : <Spinner/>}</CounterLink></div>
      </div>
      <div>
        <Box variant="awsui-key-label">Total profiles in inventory</Box>
        <div><CounterLink>{operators ? getInventoryProfilesDistribution(operators).total : <Spinner/>}</CounterLink></div>
      </div>
    </ColumnLayout>
  );
}
