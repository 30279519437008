import React from 'react';
import { StatusIndicator } from '@cloudscape-design/components';
import { timestampToDate, getIntervalSince, getLastUpdateStatus } from '../../../../utils/utils';
import TableLink from '../../../tableLink'

export const COLUMN_DEFINITIONS_MAIN = [
    {
      id: 'eid',
      header: 'Eid',
      cell: (item) => <TableLink item={item}/>,
      sortingField: 'eid',
      width: 300, 
    },
    {
      id: 'state',
      header: 'Device state',
      cell: (item) => (
        <>
          <StatusIndicator type={item.status === 'idle' ? 'success' : 'pending'}> {item.status} </StatusIndicator>
        </>
      ),
      sortingField: 'status',
      width: 150,
       
    },
    {
      id: 'lastUpdate',
      header: 'Last update',
      cell: (item) => timestampToDate(item.lastUpdate),
      sortingField: 'lastUpdate',
      width: 200,
    },
    {
      id: 'Interval',
      header: 'Interval',
      cell: (item) => <StatusIndicator type={getLastUpdateStatus(item.lastUpdate, 1440)}> {getIntervalSince(item.lastUpdate)} </StatusIndicator>,
      sortingField: 'Interval',
      width: 200,
    },
  ];