import React from 'react';
import { Link, SpaceBetween } from '@cloudscape-design/components';
import { CounterLinkVerySmall } from '../../../components/common-components';

export const COLUMN_DEFINITIONS_MAIN = [
    {
      id: 'sku',
      header: 'sku',
      cell: (item) => <SpaceBetween direction='horizontal' size="xs"><Link href="#">{item['type#key'].split('#')[1]} </Link><CounterLinkVerySmall>{"(" + item.total+")"}</CounterLinkVerySmall></SpaceBetween>,
      sortingField: 'type#key',
      width: 300, 
    },
    {
      id: 'iventory',
      header: 'inventory',
      cell: (item) => (item.inventory),
      sortingField: 'inventory',
      width: 130, 
    },
    {
      id: 'installing',
      header: 'installing',
      cell: (item) => (item.installing),
      sortingField: 'installing',
      width: 130, 
    },
    {
      id: 'enabled',
      header: 'enabled',
      cell: (item) => (item.installed_enabled),
      sortingField: 'installed_enabled',
      width: 130, 
    },
    {
      id: 'disabled',
      header: 'disabled',
      cell: (item) => (item.installed_disabled),
      sortingField: 'installed_disabled',
      width: 130, 
    },
    {
      id: 'deleted',
      header: 'deleted',
      cell: (item) => (item.deleted),
      sortingField: 'deleted',
      width: 130, 
    },
    {
      id: 'reserved',
      header: 'reserved',
      cell: (item) => (item.reserved),
      sortingField: 'reserved',
      
    },
    
  ];