
import { applyDensity, disableMotion, Density } from '@cloudscape-design/global-styles';
import '@cloudscape-design/global-styles/index.css';
import * as localStorage from './utils';

window.disableMotionForTests = disableMotion;

// always `true` in this design
export const isVisualRefresh = true;

export let currentDensity = localStorage.load('Awsui-Density-Preference') ?? Density.Comfortable;
applyDensity(currentDensity);

export function updateDensity(density) {
  applyDensity(density);
  localStorage.save('Awsui-Density-Preference', density);
  currentDensity = density
}