
import { useNavigate } from 'react-router-dom';
import SideNavigation from '@cloudscape-design/components/side-navigation';

//Context
import {
  useContextController,
  setActiveHref,
  setNotifications
} from "../../context/index.js";



const items = [
  { type: 'link', text: 'Dashboard', href: '/dashboard' },
  { type: 'link', text: 'Devices', href: '/devices' },
  { type: 'link', text: 'Profiles', href: '/profiles' },
  { type: 'link', text: 'SKUs',href: '/skus'},
];

export default function Navigation() {

  const navigate = useNavigate();

  //context hook
  const [controller, dispatch] = useContextController();
  const { activeHref } = controller;
  
  return (
    <>
      <SideNavigation
        activeHref={activeHref}
        header={{ href: '/home/index.html', text: 'Console' }}
        items={items} 
        onFollow={event => {
          if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(dispatch, event.detail.href);
            setNotifications(dispatch, [])
            navigate(event.detail.href);
          }
        }}
      />
    </>
  );
}
