// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import {Header} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import EmptyState from '../../../genericTable/emptyState'
import { isVisualRefresh } from '../../../../utils/apply-mode';
import { EmbeddedTable } from '../../embedded-table-wrapper';


import { COLUMN_DEFINITIONS_NOTIFICATIONS } from './panelConfig';

import {
  useContextController,
} from "../../../../context/index";

export const notificationsDetail = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 4 },
  data: {
    icon: 'table',
    title: 'NotificationsDetail',
    description: 'View all events for this device',
    disableContentPaddings: !isVisualRefresh,
    header: WidgetHeader,
    content: WidgetContent,
  },
};

function WidgetHeader() {
  return (
    <Header variant="h2" description="Device audit trail">
      Events history
    </Header>
  );
}


function WidgetContent() {

  //context hook
  const [controller] = useContextController();
  const {nucleusDeviceNotifications} = controller

  const { items, filterProps } = useCollection(
    nucleusDeviceNotifications ? nucleusDeviceNotifications : [],   
    {
      filtering: {
        empty: (
          <EmptyState title="No notifications" subtitle="No notifications to display." />
        ),
      },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS_NOTIFICATIONS[0], isDescending: true } },
    }
  );
  
  return <EmbeddedTable 
    resizableColumns={true} 
    items={items} 
    filterProps={filterProps} 
    columnDefinitions={COLUMN_DEFINITIONS_NOTIFICATIONS} 
    loading={ nucleusDeviceNotifications ? false : true }
    loadingText={"Loading Notifications"}
    trackBy='id'
    />;
}
