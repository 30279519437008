import { notificationsI18nStrings } from '../i18-strings/notifications';
import { interpolate } from './utils';

export function createSuccessNotification(reason, eid = null, command = null, result = null) {
    if (reason === "success") {
        const notification = {
            type: 'success',
            message: interpolate(notificationsI18nStrings.successCommand,{command: command, eid: eid, result: result}) ,
          }
        return notification
    }
    if (reason === "syncSuccess") {
      const notification = {
          type: 'success',
          message: notificationsI18nStrings.syncSuccess,
        }
      return notification
  }
    else if (reason === "info") {
        const notification = {
            type: 'info',
            message: `${notificationsI18nStrings.infoCommand}`,
          }
        return notification
    }
    else if (reason === "syncInfo") {
      const notification = {
          type: 'info',
          loading: true,
          message: `${notificationsI18nStrings.infoOperation}`,
        }
      return notification
    }
    else if (reason === "passwordReset") {
      const notification = {
          type: 'info',
          message: `${notificationsI18nStrings.passwordResetConfirmation}`,
        }
      return notification
    }
    else {
        const notification = {
            type: 'error',
            message: `${notificationsI18nStrings.default}`,
          }
        return notification

    }
    
    
}