
// Cloudscape Design components
import AppLayout from '@cloudscape-design/components/app-layout';
import SplitPanel from '@cloudscape-design/components/split-panel';
//Nucleus components
import { GetPanelContent, useSplitPanel } from './utils/utils';
import { splitPanelI18nStrings } from '../../i18-strings/split-panel';
import TopNavigationMenu from '../utils';

export default function SplitShell({ children, contentType, breadcrumbs, tools, navigation, notifications, collectionPropsSelectedItems, refresh }) {

  
  const { header: panelHeader, body: panelBody } = GetPanelContent(collectionPropsSelectedItems, refresh)
  const { splitPanelOpen, onSplitPanelToggle, splitPanelSize, onSplitPanelResize } = useSplitPanel(collectionPropsSelectedItems)

  return (
    <>
      <TopNavigationMenu/>
      <AppLayout
        contentType={contentType}
        navigation={navigation}
        breadcrumbs={breadcrumbs}
        notifications={notifications}
        stickyNotifications={true}
        tools={tools}
        content={children}
        headerSelector="#top-nav"
        splitPanelOpen={splitPanelOpen}
        onSplitPanelToggle={onSplitPanelToggle}
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={onSplitPanelResize}
        splitPanel={
          <SplitPanel 
            header={panelHeader} 
            i18nStrings={splitPanelI18nStrings}
            hidePreferencesButton= {true}
          >
            {panelBody}
          </SplitPanel>
        }
      />
    </>
  );
}
