
export const FILTERING_PROPERTIES = [
  {
    key: "id",
    propertyLabel: "id",
    groupValuesLabel: "id",
    operators: ["!:",":"],
  },
  {
    key: "eid",
    propertyLabel: "eid",
    groupValuesLabel: "eid",
    operators: ["!:",":"],
  },
  {
    key: "activeSKU",
    propertyLabel: "activeSKU",
    groupValuesLabel: "activeSKU",
    operators: ["!:",":"],
  },
  {
    key: "status",
    propertyLabel: "status",
    groupValuesLabel: "status",
    operators: ["!:",":"],
  },
];