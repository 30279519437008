
import { useRef, useState, Fragment, useEffect } from 'react';

import Board from '@cloudscape-design/board-components/board'
import Button from '@cloudscape-design/components/button';
import ContentLayout from '@cloudscape-design/components/content-layout';
import SplitPanel from '@cloudscape-design/components/split-panel';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { AppLayout } from '@cloudscape-design/components';
import Icon from '@cloudscape-design/components/icon'

import HelpPanel from '@cloudscape-design/components/help-panel';

import Breadcrumbs from '../../components/breadcrumbs';
import Navigation from '../../components/navigation';
import { Notifications } from '../../components/notifications/notifications';
import CustomModal from '../../components/modals';


import { HelpPanelProvider } from '../../hooks/useHelpPanel';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { DashboardHeader, DashboardMainInfo } from '../../components/dashboard/header';
import { DashboardEmptyState } from '../../components/dashboard/emptyState/dashboardEmptyState';
import { getBoardWidgets, getPaletteWidgets, exportLayout } from './utils/widgets';
import Palette from '../../components/dashboard/palette/palette';
import { ConfigurableWidget } from '../../components/dashboard/configurable-widget';
import { ResetButton } from '../../components/dashboard/reset-button';
import { boardI18nStrings } from '../../components/dashboard/i18n-strings';
import { useDashboardItemsLayout } from '../../hooks/useDashboardItemsLayout';
import TopNavigationMenu from '../../layouts/utils';

import { useApi } from '../../hooks/useApi';
import {fetchNucleusResource} from '../../api/nucleus'

import {
  useContextController,
  setNucleusDevices,
  setOperators,
  setDevicesCount
} from "../../context/index";



const splitPanelMaxSize = 360;

const Dashboard =  () => {
  
  const [devicesResponse, setDevicesResponse] = useApi()
  const [operatorResponse, setOperatorsResponse] = useApi()
  const [countResponse, setCountResponse] = useApi()
  // const [preferences, setPreferences] = useState({ pageSize: 20 });


  //context hook
  const [controller, dispatch] = useContextController();
  const {nucleusDevices, operators, devicesCount} = controller

  async function refresh(){
    
    const [devicesResp, operatorsResp, countResp] = await Promise.all([
      setDevicesResponse(()=>fetchNucleusResource('devices')),
      setOperatorsResponse(()=>fetchNucleusResource('skus')),
      setCountResponse(()=>fetchNucleusResource('count'))
    ]);
    /*await setLoloResponse(()=>fetchLoloResource('stats')))*/
    setNucleusDevices(dispatch, devicesResp.devices)
    setOperators(dispatch, operatorsResp.skus)     
    setDevicesCount(dispatch, countResp.devicesCount)
  }

  useEffect(() => {
    if(!nucleusDevices || !operators || !devicesCount){
      refresh()
    }
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const appLayoutRef = useRef(null);

  const [setToolsOpen] = useState(false);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelSize, setSplitPanelSize] = useLocalStorage('ConfigurableDashboards-split-panel-size', 360);
  const [ref, layout, setLayout, resetLayout] = useDashboardItemsLayout();
  const [setToolsContent] = useState(() => <DashboardMainInfo />);

  const loadHelpPanelContent = (content) => {
    setToolsOpen(true);
    setToolsContent(content);
    appLayoutRef.current?.focusToolsClose();
  };
  
  return (
    <>
    <TopNavigationMenu/>
    <HelpPanelProvider value={loadHelpPanelContent}>
    <AppLayout
    ref={appLayoutRef}
    contentType="dashboard"
    breadcrumbs={<Breadcrumbs active={[{ text: 'Dashboard', href: '/dashboard' }]} />}
    navigation={<Navigation />}
    notifications={<Notifications/>}
    tools={<HelpPanel header={<h2>Help panel</h2>}></HelpPanel>}
    content={
      <ContentLayout
        header={
        <>
          <DashboardHeader
            actions={
              <SpaceBetween size="xs" direction="horizontal">
              <ResetButton onReset={resetLayout}>Reset to default layout</ResetButton>
              <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                  Add widget
              </Button>
              <Button onClick={refresh} loading={devicesResponse.isFetching || operatorResponse.isFetching ||countResponse.isFetching} ><Icon name="refresh"/></Button>
              </SpaceBetween>
            }
            title={"Dashboard"}
          />
        </>
        }
      >
        <div ref={ref}>
        <Board
          empty={
          <DashboardEmptyState
            title="No widgets"
            description="There are no widgets on the dashboard."
            verticalCenter={false}
            action={
            <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={resetLayout}>Reset to default layout</Button>
                <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                Add widget
                </Button>
            </SpaceBetween>
            }
          />
          }
          i18nStrings={boardI18nStrings}
          items={getBoardWidgets(layout)}
          onItemsChange={({ detail: { items } }) => {
          setLayout(exportLayout(items));
          }}
          renderItem={(item, actions) => {
          const Wrapper = item.data.provider ?? Fragment;
          return (
            <Wrapper>
            <ConfigurableWidget config={item.data} onRemove={actions.removeItem} />
            </Wrapper>
          );
          }}
        />
        </div>
        <CustomModal/>
      </ContentLayout>
      }
      splitPanel={
        <SplitPanel
          header="Add widgets"
          closeBehavior="hide"
          hidePreferencesButton={true}
          i18nStrings={{
            preferencesTitle: 'Split panel preferences',
            preferencesPositionLabel: 'Split panel position',
            preferencesPositionDescription: 'Choose the default split panel position for the service.',
            preferencesPositionSide: 'Side',
            preferencesPositionBottom: 'Bottom',
            preferencesConfirm: 'Confirm',
            preferencesCancel: 'Cancel',
            closeButtonAriaLabel: 'Close panel',
            openButtonAriaLabel: 'Open panel',
            resizeHandleAriaLabel: 'Resize split panel',
          } }
        >
          <Palette items={getPaletteWidgets(layout)} />
        </SplitPanel>
      }
      splitPanelPreferences={{ position: 'side' }}
      splitPanelOpen={splitPanelOpen}
      onSplitPanelToggle={({ detail }) => setSplitPanelOpen(detail.open)}
      splitPanelSize={splitPanelSize}
      onSplitPanelResize={event => setSplitPanelSize(Math.min(event.detail.size, splitPanelMaxSize))}
    />
  </HelpPanelProvider>
  </>
  );
}

export default Dashboard;