// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Box, Link, Header } from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import EmptyState from '../../../genericTable/emptyState'
import { isVisualRefresh } from '../../../../utils/apply-mode';
import { EmbeddedTable } from '../../embedded-table-wrapper';
import { COLUMN_DEFINITIONS_MAIN } from './tableConfig';


import {
  useContextController,
} from "../../../../context/index";


export const devicesOldestTop = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 4 },
  data: {
    icon: 'table',
    title: 'Devices oldest updates top',
    description: 'View which devices are the ones taht have the oldest update',
    disableContentPaddings: !isVisualRefresh,
    header: WidgetHeader,
    content: WidgetContent,
    footer: WidgetFooter,
  },
};

function WidgetHeader() {
  return (
    <Header variant="h2" description="Top 50 devices which have the oldest date on their last agent update">
      Top devices by oldest last update
    </Header>
  );
}

function WidgetFooter() {
  return (
    <Box textAlign="center">
      <Link href="/devices">Device inventory</Link>
    </Box>
  );
}


function WidgetContent() {

  //context hook
  const [controller] = useContextController();
  const {nucleusDevices} = controller

  const { items, filterProps } = useCollection(
    nucleusDevices ? nucleusDevices : [],
    {
      filtering: {
        empty: (
          <EmptyState title="No devices" subtitle="No devices to display." />
        ),
      },
      sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS_MAIN[3], isDescending: false } },
    }
  );
 
  return <EmbeddedTable 
    resizableColumns={true} 
    items={items} 
    filterProps={filterProps}
    columnDefinitions={COLUMN_DEFINITIONS_MAIN}
    loading={ nucleusDevices ? false : true }
    loadingText={"Loading Devices"}
    trackBy='eid'
     />;
}
