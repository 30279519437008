import { useState } from "react";

import {TextContent as Text} from "@cloudscape-design/components";
import Modal from "@cloudscape-design/components/modal";
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Box from "@cloudscape-design/components/box";
import Input from "@cloudscape-design/components/input";
import FormField from "@cloudscape-design/components/form-field";

import { I18nStringsModals } from "../../i18-strings/modals";
import { I18nStringsButtons } from "../../i18-strings/buttons";

import { interpolate } from "../../utils/utils";

export default function DevicesModalDelete ({handleDismiss, handleAction, modal, monitored=true}) {

  const [value, setValue] = useState("");
  const [error, setError] = useState(false)
  return (
    <Modal
      onDismiss={handleDismiss}
      visible={true}
      footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
        <Button variant="link" onClick={handleDismiss}>{I18nStringsButtons.cancel}</Button>
        <Button 
          variant="primary" 
          onClick={()=> {
            if (modal.id===value) {
              handleAction("deleteDevice")
              setError(false)
            }
            else {
              setError(true)
            }
          }}
        >
          {I18nStringsButtons.delete}
        </Button>
        </SpaceBetween>
        </Box>
        }
      header={I18nStringsModals.deleteDeviceHeader}
    >
      <SpaceBetween direction="vertical" size="s">
        {monitored ? <Text>{I18nStringsModals.deleteDeviceLoloStart}<strong>{interpolate(I18nStringsModals.deviceEid, {eid: modal.id})}</strong>{I18nStringsModals.deleteDeviceEnd}</Text> : <Text>{I18nStringsModals.deleteDeviceStart}<strong>{interpolate(I18nStringsModals.deviceEid, {eid: modal.id})}</strong>{I18nStringsModals.deleteDeviceEnd}</Text>} 
        <FormField
          errorText={ error  ? I18nStringsModals.errorValidationId : undefined}
        >
          <Input
          onChange={({ detail }) => setValue(detail.value)}
          onFocus={()=>setError(false)}
          value={value}
          placeholder="Device eid"
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
}