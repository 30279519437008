import React from 'react';
//import { useState } from 'react';
import Flashbar from '@cloudscape-design/components/flashbar';

//Context
import {
  useContextController,
  setNotifications,
} from "../../context/index.js";

function useNotifications(notifications, handleDismissNotification) {
  const cloudScapeNotifications = [];

  for ( let id in notifications) {
    const {type, message, loading=false} = notifications[id]
    cloudScapeNotifications.push({
      type: type,
      loading: loading,
      content: message,
      statusIconAriaLabel: type,
      dismissLabel: 'Dismiss message',
      dismissible: true,
      onDismiss: () => handleDismissNotification(id),
      id: id,
    });
  }
  return cloudScapeNotifications;
}

export function Notifications() {
  //const [refreshNotifications, setRefreshNotifications] = useState(false)
  //context hook
  const [controller, dispatch] = useContextController();
  const { notifications } = controller;

  function handleDismissNotification(id) {
    delete notifications[id]
    setNotifications(dispatch, notifications)
    //setRefreshNotifications(!refreshNotifications)
  } 

  const cloudScapeNotifications = useNotifications(notifications, handleDismissNotification);
  return <Flashbar items={cloudScapeNotifications} stackItems={true} />;
}