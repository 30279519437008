import { useState } from 'react';
import { createErrorNotification } from '../utils/createErrorNotification';
import { createSuccessNotification } from '../utils/createSuccessNotification';
import { v4 as uuid4 } from 'uuid';
//Context
import {
  useContextController,
  setNotifications,
  setIsFetching,
} from "../context/index";

export function useApiWithFeedback() {
    //context hook
  const [controller, dispatch] = useContextController();
  const { notifications } = controller;

  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
    isSuccess: false
  })

  async function fetchMethod (apiFunction) {
    setResponse({
      data: null,
      isFetching: true,
      error: null,
      isSuccess: false
    });
    setIsFetching(dispatch, true)
    //User feedback
    notifications[uuid4()] = createSuccessNotification("info")
    setNotifications(dispatch, notifications)

    //Execute API call
    try {
      const response = await apiFunction ()
      setResponse({
        data: response,
        isFetching: false,
        error: null,
        isSuccess: true
      });
      setIsFetching(dispatch, false)
      return response
    }
    catch (err) {
      setResponse({
        data: null,
        isFetching: false,
        error: true,
        isSuccess: false
      });
      setIsFetching(dispatch, false)
      notifications[uuid4()] = createErrorNotification(err)
      setNotifications(dispatch, notifications)
    }
  }

  return [response, fetchMethod];
}

