
export const mixedContent = {
    light: require('../../../assets/images/mixed-content-light.svg').default,
    dark: require('../../../assets/images/mixed-content-dark.svg').default,
  };
  
  export const barChart = {
    light: require('../../../assets/images/bar-chart-light.svg').default,
    dark: require('../../../assets/images/bar-chart-dark.svg').default,
  };
  
  export const lineChart = {
    light: require('../../../assets/images/line-chart-light.svg').default,
    dark: require('../../../assets/images/line-chart-dark.svg').default,
  };
  
  export const list = {
    light: require('../../../assets/images/list-light.svg').default,
    dark: require('../../../assets/images/list-dark.svg').default,
  };
  
  export const pieChart = {
    light: require('../../../assets/images/pie-chart-light.svg').default,
    dark: require('../../../assets/images/pie-chart-dark.svg').default,
  };
  
  export const table = {
    light: require('../../../assets/images/table-light.svg').default,
    dark: require('../../../assets/images/table-dark.svg').default,
  };