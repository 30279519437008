
import { useRef, useState, Fragment, useEffect } from 'react';

import Board from '@cloudscape-design/board-components/board'
import Button from '@cloudscape-design/components/button';
import ContentLayout from '@cloudscape-design/components/content-layout';
import SplitPanel from '@cloudscape-design/components/split-panel';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { AppLayout, Icon} from '@cloudscape-design/components';

import HelpPanel from '@cloudscape-design/components/help-panel';

import Breadcrumbs from '../../components/breadcrumbs';
import Navigation from '../../components/navigation';
import { Notifications } from '../../components/notifications/notifications';
import CustomModal from '../../components/modals';
import TopNavigationMenu from '../../layouts/utils';


import { HelpPanelProvider } from '../../hooks/useHelpPanel';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { DashboardHeader, DashboardMainInfo } from '../../components/dashboard/header';
import { DashboardEmptyState } from '../../components/dashboard/emptyState/dashboardEmptyState';
import { getBoardWidgets, getPaletteWidgets, exportLayout } from './utils/widgets';
import Palette from '../../components/dashboard/palette/palette';
import { ConfigurableWidget } from '../../components/dashboard/configurable-widget';
import { ResetButton } from '../../components/dashboard/reset-button';
import { boardI18nStrings } from '../../components/dashboard/i18n-strings';
import {useDeviceDetailItemsLayout} from '../../hooks/useDeviceDetailItemsLayout'
import {
  useContextController,
  setNucleusDevices,
  setSelectedDevice,
  setNucleusDeviceNotifications,
  setOperators
} from "../../context/index";

import { fetchNucleusResource } from '../../api/nucleus';
import { useApi } from '../../hooks/useApi';


const splitPanelMaxSize = 360;

const DeviceDetail =  () => {

  const appLayoutRef = useRef(null);

  const [setToolsOpen] = useState(false);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelSize, setSplitPanelSize] = useLocalStorage('DeviceDetail-split-panel-size', 360);
  const [ref, layout, setLayout, resetLayout] = useDeviceDetailItemsLayout();
  const [setToolsContent] = useState(() => <DashboardMainInfo />);

  //context hook
  const [controller, dispatch] = useContextController();
  const {selectedDevice, operators} = controller

  const [notificationsResponse, setNotificationsResponse] = useApi()
  const [deviceResponse, setDeviceResponse] = useApi()
  const [skuResponse, setSkuResponse] = useApi()


  const loadHelpPanelContent = (content) => {
    setToolsOpen(true);
    setToolsContent(content);
    appLayoutRef.current?.focusToolsClose();
  };

  async function refresh_notifications() {
    const query = encodeURIComponent(JSON.stringify({ eid: selectedDevice.eid }))
    const resource =  `notifications/?filter=${query}`
    const notifications = await setNotificationsResponse(()=>fetchNucleusResource(resource))
    setNucleusDeviceNotifications(dispatch, notifications.notifications)

  }

  async function refresh_selected_device() {
    const resp = await setDeviceResponse(()=>fetchNucleusResource('devices'))
    setNucleusDevices(dispatch, resp.devices)
    const updatedSelectedDevice = resp.devices.filter(elem => {
      return  (selectedDevice.eid === elem.eid) ;
    });
    setSelectedDevice(dispatch, updatedSelectedDevice[0])
  }

  async function refreshSkus() {
    const resp = await setSkuResponse(()=>fetchNucleusResource('skus'))
    await setOperators(dispatch, resp.skus)
  }

  async function refresh(){
    /*await setLoloResponse(()=>fetchLoloResource('stats')))*/ 
    await refresh_selected_device()   
    refresh_notifications()
    refreshSkus()
    
  }

  useEffect(() => {
    if (selectedDevice === false) {
      window.location.href = "/devices"
    }
    if(!operators || operators === null){
      refreshSkus()
      }
  },[]);// eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
    <TopNavigationMenu/>
    <HelpPanelProvider value={loadHelpPanelContent}>
    <AppLayout
    ref={appLayoutRef}
    contentType="dashboard"
    breadcrumbs={<Breadcrumbs active={[{ text: 'Devices', href: '/devices' }, { text: 'Device detail', href: '/devices/device-detail' }]} />}
    navigation={<Navigation />}
    notifications={<Notifications/>}
    tools={<HelpPanel header={<h2>Help panel</h2>}></HelpPanel>}
    content={
      <ContentLayout
        header={
        <>
          <DashboardHeader
            actions={
              <SpaceBetween size="xs" direction="horizontal">
              <ResetButton onReset={resetLayout}>Reset to default layout</ResetButton>
              <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                  Add widget
              </Button>
              <Button onClick={refresh} loading={deviceResponse.isFetching || notificationsResponse.isFetching} ><Icon name="refresh"/></Button>
              </SpaceBetween>}
            title={"Device detail"}
          />
        </>
        }
      >
        <div ref={ref}>
        <Board
          empty={
          <DashboardEmptyState
            title="No widgets"
            description="There are no widgets on the dashboard."
            verticalCenter={false}
            action={
            <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={resetLayout}>Reset to default layout</Button>
                <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                Add widget
                </Button>
            </SpaceBetween>
            }
          />
          }
          i18nStrings={boardI18nStrings}
          items={getBoardWidgets(layout)}
          onItemsChange={({ detail: { items } }) => {
          setLayout(exportLayout(items));
          }}
          renderItem={(item, actions) => {
          const Wrapper = item.data.provider ?? Fragment;
          return (
            <Wrapper>
            <ConfigurableWidget config={item.data} onRemove={actions.removeItem} />
            </Wrapper>
          );
          }}
        />
        </div>
        <CustomModal/>
      </ContentLayout>
      }
      splitPanel={
        <SplitPanel
          header="Add widgets"
          closeBehavior="hide"
          hidePreferencesButton={true}
          i18nStrings={{
            preferencesTitle: 'Split panel preferences',
            preferencesPositionLabel: 'Split panel position',
            preferencesPositionDescription: 'Choose the default split panel position for the service.',
            preferencesPositionSide: 'Side',
            preferencesPositionBottom: 'Bottom',
            preferencesConfirm: 'Confirm',
            preferencesCancel: 'Cancel',
            closeButtonAriaLabel: 'Close panel',
            openButtonAriaLabel: 'Open panel',
            resizeHandleAriaLabel: 'Resize split panel',
          } }
        >
          <Palette items={getPaletteWidgets(layout)} />
        </SplitPanel>
      }
      splitPanelPreferences={{ position: 'side' }}
      splitPanelOpen={splitPanelOpen}
      onSplitPanelToggle={({ detail }) => setSplitPanelOpen(detail.open)}
      splitPanelSize={splitPanelSize}
      onSplitPanelResize={event => setSplitPanelSize(Math.min(event.detail.size, splitPanelMaxSize))}
    />
  </HelpPanelProvider>
  </>
  );
}

export default DeviceDetail;