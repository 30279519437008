// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Header, PieChart, Box } from '@cloudscape-design/components';
import { percentageFormatter } from '../chart-commons';
import { ResponsiveChartContainer } from '../../responsive-chart-container';

import {
  useContextController,
} from "../../../../context/index";
import { getInventoryProfilesDistribution } from '../../../../utils/NucleusDataFormatter';

export const inventoryOperatorsDistribution = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'pieChart',
    title: 'Inventory profiles distribution',
    description: 'Service providers providing inventory profiles',
    header: ZoneStatusHeader,
    content: ZoneStatusContent,
    staticMinHeight: 450,
  },
};

function ZoneStatusHeader() {
  return (
    <Header variant="h2" description="Data on inventory profiles">
      Inventory profiles distribution
    </Header>
  );
}

function getChartSize(height) {
  if (height < 150) {
    return 'small';
  }
  if (height < 300) {
    return 'medium';
  }
  return 'large';
}

function ZoneStatusContent() {
  //context hook
  const [controller] = useContextController();
  const {operators} = controller

  const {data, total} = getInventoryProfilesDistribution(operators) 
  
  const hideFilter = true;
  return (
    <ResponsiveChartContainer
      hideFilter={hideFilter}
      render={height => (
        <PieChart
          empty={
            <Box textAlign="center" color="inherit">
              <b>No data available</b>
              <Box variant="p" color="inherit">
                No profiles in inventory
              </Box>
            </Box>
          }
          variant="donut"
          innerMetricDescription="total"
          innerMetricValue={total}
          size={getChartSize(height)}
          data={
            data
          }
          hideLegend={true}
          hideFilter={hideFilter}
          segmentDescription={(datum, sum) => `${datum.value} profiles, ${percentageFormatter(datum.value / sum)}`}
          detailPopoverContent={(datum, sum) => [
            {
              key: 'Profiles count',
              value: datum.value,
            },
            {
              key: 'Percentage',
              value: percentageFormatter(datum.value / sum),
            },
          ]}
        />
      )}
    />
  );
}


