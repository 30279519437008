
import {
  profilesDetail,
  deviceData,
  notificationsDetail,
} from '../../../components/dashboard/widgets';


export const allWidgets = {
  profilesDetail,
  deviceData,
  notificationsDetail
};

const defaultLayout = [
  { id: 'deviceData' },
  { id: 'profilesDetail' },
  { id: 'notificationsDetail' },
  
];

// function merge (src,overrides) {
//   return src.map(item => {
//     const match = overrides.find(override => override.id === item.id);
//     return match ? { ...item, ...match } : item;
//   });
// }

export function getDefaultLayout(width) {
  if (width >= 2160) {
    // 6-col layout
    return defaultLayout;
    /*return merge(defaultLayout, [
      { id: 'serviceOverview', columnOffset: { '6': 0 } },
      { id: 'serviceHealth', columnOffset: { '6': 3 } },
      { id: 'instanceHours', columnOffset: { '6': 4 } },
      { id: 'networkTraffic', columnOffset: { '6': 0 } },
      { id: 'profilesDetail', columnOffset: { '6': 4 }, columnSpan: 2, rowSpan: 4 },
      { id: 'instanceLimits', columnOffset: { '6': 4 } },
      { id: 'zoneStatus', columnOffset: { '6': 2 } },
      { id: 'events', columnOffset: { '6': 0 } },
      { id: 'featuresSpotlight', columnOffset: { '6': 4 }, columnSpan: 2, rowSpan: 4 },
      { id: 'accountAttributes', columnOffset: { '6': 2 }, columnSpan: 2, rowSpan: 2 },
    ]);*/
  }
  if (width > 1045) {
    // 4-col layout with 4-col overview
    return defaultLayout;
  }
  if (width > 911) {
    // 4-col layout with 2-col overview
    return defaultLayout;
  }
  if (width > 708) {
    // 2-col layout with 4-col overview
    return defaultLayout;
  }
  if (width > 687) {
    // 2-col layout with 2-col overview
    return defaultLayout;
  }
  if (width > 485) {
    // 1-col layout with 2-col overview
    return defaultLayout;
  }
  // 1-col layout with 1-col overview
  return defaultLayout;
}

export function exportLayout(items){
  return items.map(item => ({
    id: item.id,
    columnSpan: item.columnSpan,
    columnOffset: item.columnOffset,
    rowSpan: item.rowSpan,
  }));
}

export function getBoardWidgets(layout) {
  return layout.map(position => {
    const widget = allWidgets[position.id];
    return {
      ...position,
      ...widget,
      columnSpan: position.columnSpan ?? widget.definition?.defaultColumnSpan ?? 1,
      rowSpan: position.rowSpan ?? widget.definition?.defaultRowSpan ?? 2,
    };
  });
}

export function getPaletteWidgets(layout) {
  return Object.entries(allWidgets)
    .filter(([id]) => !layout.find(position => position.id === id))
    .map(([id, widget]) => ({ id, ...widget }));
}