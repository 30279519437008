
import PanelButton from "../components/panelButton";

export const COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE = [
  {
    id: 'iccid',
    header: 'iccid',
    cell: (item) => { 
      return item.iccid},
  },
  {
    id: 'operator',
    header: 'sku',
    cell: (item) => item.sku,
  },
  {
    id: 'state',
    header: 'status',
    cell: (item) => item.status,
  },
  {
    id: 'enable',
    header: 'Enable',
    cell: (item) => {
      if (item.status.split('_')[1]==="enabled" || item.deviceStatus !=="idle" || item.status.split('#')[0]==="deleted" || item.status.split('#')[0]==="installing_error") {
        return (<>
        <PanelButton disabled={true} variant="primary" text="Enable" iccid={item.iccid} eid={item.eid} deviceId={item.deviceId}/>
        </>)
      }
      else {
        return (<>
          <PanelButton disabled={false} variant="primary" text="Enable" iccid={item.iccid} eid={item.eid} deviceId={item.deviceId}/>
          </>)
      }  
    },
    width: 150,
      
  },
  {
    id: 'delete',
    header: 'Delete',
    cell: (item) => {
      if (item.status.split('_')[1]==="enabled" || item.deviceStatus !=="idle" || item.status.split('#')[0]==="deleted" ) {
        return (<>
        <PanelButton disabled={true} variant="primary" text="Delete" iccid={item.iccid} eid={item.eid} deviceId={item.deviceId}/>
        </>)
      }
      else {
        return (<>
          <PanelButton disabled={false} variant="primary" text="Delete" iccid={item.iccid} eid={item.eid} deviceId={item.deviceId}/>
          </>)
      }
    },
    width: 150,
      
  },
  {
    id: 'reinstall',
    header: 'Reinstall',
    cell: (item) => {
      if ((item.status.split('#')[0]==="installing_error" || (item.reInstallable && item.status.split('#')[0]==="deleted")) && item.deviceStatus ==="idle" ) {
        return (<>
        <PanelButton disabled={false} variant="primary" text="Reinstall" iccid={item.iccid} eid={item.eid} deviceId={item.deviceId}/>
        </>)
      }
      else {
        return (<>
          <PanelButton disabled={true} variant="primary" text="Reinstall" iccid={item.iccid} eid={item.eid} deviceId={item.deviceId}/>
          </>)
      }
    },
    width: 150,
      
  },
];


