import { useState } from "react";

import {TextContent as Text} from "@cloudscape-design/components";
import Modal from "@cloudscape-design/components/modal";
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Box from "@cloudscape-design/components/box";
import Input from "@cloudscape-design/components/input";
import FormField from "@cloudscape-design/components/form-field";

import { I18nStringsModals } from "../../i18-strings/modals";
import { I18nStringsButtons } from "../../i18-strings/buttons";



export default function OperatorModalCreate ({handleDismiss, handleAction, modal, monitored=true}) {

  const [value, setValue] = useState("");
  const [error, setError] = useState(false)

  return (
    <Modal
      onDismiss={handleDismiss}
      visible={true}
      footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
        <Button variant="link" onClick={handleDismiss}>{I18nStringsButtons.cancel}</Button>
        <Button 
          variant="primary" 
          onClick={()=> {
              handleAction("createOperator", value)
              setError(false)
          }}
        >
          {I18nStringsButtons.create}
        </Button>
        </SpaceBetween>
        </Box>
        }
      header={I18nStringsModals.createOperatorHeader}
    >
      <SpaceBetween direction="vertical" size="s">
        <Text>{I18nStringsModals.createOperatorDescription}</Text>
        <FormField
          errorText={ error  ? I18nStringsModals.errorValidationId : undefined}
        >
          <Input
          onChange={({ detail }) => setValue(detail.value)}
          onFocus={()=>setError(false)}
          value={value}
          placeholder={I18nStringsModals.createOperatorPlaceholder}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
}