// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Box, Header, StatusIndicator, ColumnLayout, Spinner } from '@cloudscape-design/components';
import { timestampToDate } from '../../../../utils/utils';

import {
  useContextController,
} from "../../../../context/index";


export const deviceData = {
  definition: { defaultRowSpan: 2, defaultColumnSpan: 4 },
  data: {
    icon: 'list',
    title: 'Device Data',
    description: 'General information about the device',
    header: DeviceDataHeader,
    content: DeviceDataContent,
  },
};

function DeviceDataHeader() {
  return (
    <Header
      variant="h2" 
    >
      Device Data
    </Header>
  );
}

export default function DeviceDataContent() {
  //context hook
  const [controller] = useContextController();
  const {selectedDevice} = controller

  

  return (
    <ColumnLayout columns={3}>     
      <div>
        <Box variant="awsui-key-label">Status</Box>
        <div><StatusIndicator type={selectedDevice ? (selectedDevice.status === 'idle' ? 'success' : 'pending') : ""}> {selectedDevice ? selectedDevice.status :  <Spinner/>} </StatusIndicator></div>
      </div> 
      <div>
        <Box variant="awsui-key-label">Last agent update</Box>
        <div style={{ fontSize: '14px' }}>{selectedDevice ? timestampToDate(selectedDevice.lastUpdate) : <Spinner/>}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Device Id</Box>
        <div style={{ fontSize: '14px' }}>{selectedDevice ? selectedDevice.deviceId : <Spinner/>}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Eid</Box>
        <div style={{ fontSize: '14px' }}>{selectedDevice ? selectedDevice.eid : <Spinner/>}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Active SKU</Box>
        <div style={{ fontSize: '14px' }}>{selectedDevice ? selectedDevice.activeSKU : <Spinner/>}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">IMEI</Box>
        <div style={{ fontSize: '14px' }}>{selectedDevice ? selectedDevice.imei ? selectedDevice.imei : "N/A" : <Spinner/>}</div>
      </div> 
    </ColumnLayout>
  
  );
}
