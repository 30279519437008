import React from 'react';
import { StatusIndicator } from '@cloudscape-design/components';
import { timestampToDate } from '../../../utils/utils';
import TableLink from '../../../components/tableLink'

export const COLUMN_DEFINITIONS_MAIN = [
    {
      id: 'eid',
      header: 'eid',
      cell: (item) => <TableLink item={item}/>,
      sortingField: 'eid',
      width: 300, 
    },
    {
      id: 'active SKU',
      header: 'activeSKU',
      cell: (item) => item.activeSKU,
      sortingField: 'activeSKU',
      width: 130, 
    },
    {
      id: 'state',
      header: 'status',
      cell: (item) => (
        <>
          <StatusIndicator type={item.status === 'idle' ? 'success' : 'pending'}> {item.status} </StatusIndicator>
        </>
      ),
      sortingField: 'status',
      width: 110, 
    },
    {
      id: 'id',
      header: 'id',
      cell: (item) => item.deviceId,
      sortingField: 'id',
      width: 180, 
    },
    
    {
      id: 'lastUpdate',
      header: 'last Update',
      cell: (item) => timestampToDate(item.lastUpdate),
      sortingField: 'lastUpdate',
      width: 180,
    },
    {
      id: 'totalProfiles',
      header: 'profiles',
      cell: (item) => item.numProfiles,
      sortingField: 'totalProfiles',
      
    },
  ];