
export const COLUMN_DEFINITIONS_NON_EUICC_PROFILE_TABLE = [
  {
    id: 'iccid',
    header: 'Iccid',
    cell: (item) => item.iccid,
  },
  {
    id: 'operator',
    header: 'Operator',
    cell: (item) => item.operatorName,
  },
  {
    id: 'state',
    header: 'State',
    cell: (item) => item.status,
  }, 
];


