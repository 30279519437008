
import Form from "@cloudscape-design/components/form";
import Box from "@cloudscape-design/components/box";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";

import { signinI18nStrings } from "../../i18-strings/signin";
import { Fragment } from "react";

function GenericFormFields ({items, state, setState}) {
  
  return  items.map(item => (
    // Without the `key`, React will fire a key warning
    <Fragment key={item.id}>
      <Box margin={{bottom: "l"}}>
      <FormField 
        label={item.label} 
        errorText={state[item.validatedId] ? signinI18nStrings.formValidationError : undefined}
        >
        <Input 
        placeholder= {state[item.focusId] ? "" : item.label} 
        onFocus={()=>{
          const newState = { ...state };
          newState[item.focusId] = true;
          newState[item.validatedId] = false;
          setState(newState)}} 
        onBlur={()=>{
          const newState = { ...state };
          newState[item.focusId] = false;
          setState(newState)}} 
        onChange={({ detail }) => {
          const newState = { ...state };
          newState[item.id] = detail.value;
          setState(newState)}}
        value={state[item.id]}
        type={item.id ==="Password" ? "password" : 'text'}
        />
      </FormField>
      </Box>
    </Fragment>
    )) 
}

export default function GenericForm ({ header, description, items, state, setState, children }) {

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form 
        variant="embedded"
        header={<Header variant="h1" description={description}>{header}</Header>}
      >
        <Container>
        {children}
            <GenericFormFields items = {items} state={state} setState={setState} />
            
        </Container>
        
      </Form>
    </form>
  );
}