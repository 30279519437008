export const I18nStringsModals = {
    deleteDeviceHeader: 'Delete device?',
    deleteDeviceStart: 'This action cannot be reversed. Please enter device eid: ',
    deleteDeviceLoloStart: 'This action cannot be reversed. Please enter device eid: ',
    deviceEid: '{eid}',
    deleteDeviceEnd: ' below.',
    errorValidationId: 'value does not match selected resource',
    bulkUploadHeader: 'CSV file selection',
    bulkUploadDescription: 'CSV file with profile information. Learn more about supported format.',
    bulkUploadButtonText: 'Choose file to upload',
    bulkUploadAPNHeader: 'Activate APN management (optional)',
    bulkUploadError: 'An error occured',
    bulkUploadFileError: 'Please select a csv file before uploading',
    bulkUploadOperatorError: 'Please select an operator for the profiles',
    bulkUploadApnDisclaimer:'Most IoT device have some sort of APN management and you should not fill in the form below. If you fill in the form below, Nucleus LPA will modify APN modem settings after a profile enable in order to achieve connectivity.',
    bulkUploadApnLabel: "APN",
    bulkUploadApnUserLabel: "APN user (optional)",
    bulkUploadApnPasswordLabel: "APN password (optional)", 
    createOperatorHeader: 'New operator id',
    createOperatorDescription: 'Please enter a new unique operator id. We recommend using descriptor including profile\'s country',
    createOperatorPlaceholder: 'ex. Operator1 Spain or Operator2 Global',
    confirmDeleteHeader: 'Delete confirmation',
    confirmDeleteDescription: 'Please enter delete below to confirm deletion',
    operatorsDeleteDescritpion: 'Operator tags can only be deleted if they have no associacted profiles (except for purged ones). Please enter delete below to confirm deletion',
    confirmDeletePlaceholder: 'delete',
    installProfileHeader: 'Install profile',
    installProfileDescription: 'Please select and operator with profiles in inventory',
    chooseOperatorError: 'Please select an operator',
    chooseOperatorPlaceholder: 'Choose an operator',
    errorFetchingOperators: 'Error fetching operators',
    chooseOperatorEmpty: 'No operators available',
    createProfileHeader: 'New profile',
    createProfileDescription: 'Please fill in all mandatory fields, apn parameters are only needed if you want us to try to manage apn settings when activating this profile on a device',
    createProfileIccidLabel: "ICCID",
    createProfileActivationCodeLabel: "Activation code",
    createProfileAddressLabel: "SM-DP Address",
    createProfileApnLabel: "APN (optional)",
    createProfileApnUserLabel: "APN User (optional)",
    createProfileApnPasswordLabel: "APN Password (optional)",

    
};