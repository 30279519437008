
import { useEffect, useRef, useState } from 'react';
import { useContainerQuery } from '@cloudscape-design/component-toolkit';
import { load, remove, save } from '../utils/utils';
import { getDefaultLayout } from '../pages/deviceDetail/utils/widgets';


const storageKey = 'DeviceDetailDashboards-widgets-layout';

export function useDeviceDetailItemsLayout() {
  const [width, ref] = useContainerQuery(entry => entry.contentBoxWidth);
  const [layout, setLayout] = useState(() => load(storageKey) ?? null);
  const itemsChanged = useRef(layout !== null);

  useEffect(() => {
    if (itemsChanged.current || !width) {
      return;
    }
    setLayout(getDefaultLayout(width));
    
  }, [width]);

  function handleLayoutChange(layout) {
    itemsChanged.current = true;
    save(storageKey, layout);
    setLayout(layout);
  }

  function resetLayout() {
    itemsChanged.current = false;
    remove(storageKey);
    setLayout(getDefaultLayout(width));
  }

  return [ref, layout ?? [], handleLayoutChange, resetLayout];
}