import ModalSelection from "./modalSelection.js";
import { useSyncApiWithFeedback } from "../../hooks/useSyncApiWithFeedback.js";
import { useApiWithFeedback } from "../../hooks/useApiWithFeedback.js";
import { postNucleusResource, deleteNucleusResourceById, executeCommand } from "../../api/nucleus/index.js";
//Context
import {
    useContextController,
    setModal,
    setNotifications
  } from "../../context/index.js";

export default function CustomModal(){

  //context hook
  const [controller, dispatch] = useContextController();
  const { modal, notifications } = controller;
  const [, setNucleusResponse] = useSyncApiWithFeedback()
  const [, setInstallResponse] = useApiWithFeedback()

  function notificationCallback(notification) {
    const keys = Object.keys(notification)
    keys.forEach(key => {
        notifications[key] = notification[key]
      });
    setNotifications(dispatch, notifications)
}

  function handleDismiss(){
    setModal(dispatch, null)
  }

  async function handleAction(type, value) {
    const payload={}
    switch (type) {
        case 'deleteDevice':
          console.log('El kilogramo de naranjas cuesta $0.59.');
          break;
        case 'installProfile':
          handleDismiss()
          payload['sku'] = value
          payload['eid'] = modal.eid
          setInstallResponse (() => executeCommand('INSTALL_AUTO', payload, notificationCallback)); 
          break;
        case 'createOperator':
          handleDismiss()
          payload['sku'] = value
          await setNucleusResponse(()=>postNucleusResource('skus', payload))
          modal.refresh()
          break;
        case 'deleteOperator':
          handleDismiss()
          await setNucleusResponse(()=>deleteNucleusResourceById('skus', value))
          modal.refresh()
          break;
        case 'campaign':
          console.log('El kilogramo de cerezas cuesta $3.00.');
          break;
        default:
          console.log('Lo lamentamos, por el momento no disponemos de ' );
      }
    setModal(dispatch, null)
  }

  return <ModalSelection modal={modal} handleDismiss={handleDismiss} handleAction={handleAction}/>


}