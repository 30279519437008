import React from "react";
import { useEffect } from "react";

import { Auth } from 'aws-amplify';
import PrivateRoutes from "./utils/PrivateRoutes";
import SignIn from "./pages/authentication";

// react-router components
import { Routes, Route, Navigate } from "react-router-dom";
import "@cloudscape-design/global-styles/index.css"

import {
  useContextController,
  setUser,
} from "./context/index";


import routes from "./routes";


function App() {

  //context hook
  const [controller, dispatch] = useContextController();
  const {user} = controller
  

  // Setting page scroll to 0 when changing the route and get user auth state
  useEffect(() => {

    const getUser =  async () =>{
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const user_att = await  Auth.userAttributes(cognitoUser);
        const user = Object.assign({}, ...user_att.map((x) => ({[x.Name]: x.Value})));
        setUser(dispatch, user)
  
      }
      catch(error){
        setUser(dispatch, "NONE");
      }
    }

    document.documentElement.scrollTop = 0;
    if (!document.scrollingElement) {
      return;
    }

    if (user === null){
      getUser();
    }    
  }, [user, dispatch]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        return <Route path={route.route} element={route.page} key={route.key} />;
      }
      return null;
  });

  return (   
    (user === null) ? <div/> : 
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route element={<PrivateRoutes user={user}/>}>
          {getRoutes(routes)}
        </Route>
        <Route path="*" element={<Navigate to="/dashboard"/>} />
      </Routes> 
  
  );
}
export default App;