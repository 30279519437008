
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Grid from "@cloudscape-design/components/grid";
import Box from "@cloudscape-design/components/box";
import UIBox from "@mui/material/Box";
import Typography from '@mui/material/Typography';


import { v4 as uuid4 } from 'uuid';

import ButtonForm from "./components/buttonForm";
import SignInForm from "./components/signinForm";
import Cover from "./components/cover";
import { Notifications } from '../../components/notifications/notifications';
import { signinI18nStrings } from '../../i18-strings/signin';
import { createErrorNotification } from '../../utils/createErrorNotification';
import { SIGNIN_FORM_CONFIG, validateOnlyEmptyFields, SIGNUP_FORM_CONFIG } from './utils/signinConfig';

//Context
import {
  useContextController,
  setUser,
  setNotifications,
  setCognitoUser,
} from "../../context/index";

//API
import { Auth } from 'aws-amplify';

export default function SignIn () {
  const [fetching, setFetching] = useState(false)
  const [showSignUp, toggle] = useState(false)

  //context hook
  const [controller, dispatch] = useContextController();
  const { notifications } = controller;
  //page redirection
  const navigate = useNavigate()

  const defaultSignInState = {
    UserName: '',
    focusUserName: false,
    isEmptyUserName: false,
    Password: '',
    focusPassword: false,
    isEmptyPassword: false,
    seePassword: false,
    
  };

  const defaultSignUpState = {
    AccountName: '',
    focusAccountName: false,
    isEmptyAccountName: false,
    UserName: '',
    focusUserName: false,
    isEmptyUserName: false,
    Email: '',
    focusEmail: false,
    isEmptyEmail: false,
  };

  const [signInState, setSignInState] = useState(defaultSignInState)
  const [signUpState, setSignUpState] = useState(defaultSignUpState)

  async function handleSignUp() {
  }

  //Handle Login
  async function handleLogin() {
    if (!(validateOnlyEmptyFields(signInState, setSignInState))) {
      setFetching(true) 
      const compoundUserName = signInState.UserName + '-' + signInState.AccountNumber     
      try {
        const cognitoUserResponse = await Auth.signIn(compoundUserName, signInState.Password)
        setCognitoUser(dispatch, cognitoUserResponse)
        setFetching(false)
        if (cognitoUserResponse['challengeName']==='NEW_PASSWORD_REQUIRED') {
          const userObject = "passwordReset";
          setUser(dispatch, userObject) 
          navigate("/password-reset")
        }
        else {  
          const response = await  Auth.userAttributes(cognitoUserResponse);
          const userObject = Object.assign({}, ...response.map((x) => ({[x.Name]: x.Value})));
          setUser(dispatch, userObject)
          navigate("/dashboard")
        }
        
      }catch (error){
        notifications[uuid4()] = createErrorNotification(error)
        setNotifications(dispatch, notifications)
        setFetching(false)
      }
    }

  }

  //Handle Cover Toggle to whow signUP or SignIn
  async function handleCoverToggle() {
    toggle(!showSignUp)
    await new Promise(r => setTimeout(r, 500));
    setSignInState(defaultSignInState)
    setSignUpState(defaultSignUpState)
  }

  return (
    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
      <UIBox
      position="absolute" top={0} left={0} 
      width={"100%"} 
      height={"125%"}
      >
      <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xxxl", right: "xxxl", left: "xxxl"}}>
        <Cover showSignUp={showSignUp}/>
        <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xl", right: "xl", left: "xl"}}>
          <SignInForm
            header={signinI18nStrings.signInHeaderForm}
            description={signinI18nStrings.signInDescriptionForm}
            items={SIGNIN_FORM_CONFIG}
            state={signInState}
            setState={setSignInState}
          />
          <ButtonForm text='Sign in' handleClick={handleLogin} loading={fetching} testid="signin-button"/>
          {/*<UIBox  display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                marginBottom={3}
              >
                <Typography mt={3} mr={1} variant="p" >{signinI18nStrings.signInSwap}</Typography>
                <Typography mt={3} variant="p" component={Link} color='blue' onClick={handleCoverToggle} data-testid="signin-link">{signinI18nStrings.signInLink}</Typography>
  </UIBox>*/}
        </Box>
        <Notifications/>
      </Box>
      </UIBox>
      <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xxxl", right: "xxxl", left: "xxxl"}}>
        <Box margin= {{top: "xxxl", right: "xxxl", left: "xxxl"}} padding={{top: "xl", right: "xl", left: "xl"}}>
        <SignInForm
            header={signinI18nStrings.signUpHeaderForm}
            description={signinI18nStrings.signUpDescriptionForm}
            items={SIGNUP_FORM_CONFIG}
            state={signUpState}
            setState={setSignUpState}
          />
          <ButtonForm text='Sign up' handleClick={handleSignUp} loading={fetching} testid="signup-button"/>
          <UIBox  display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography mt={3} mr={1} variant="p" >{signinI18nStrings.signUpSwap}</Typography>
                <Typography mt={3} variant="p" component={Link} color='blue' onClick={handleCoverToggle} data-testid="signup-link">{signinI18nStrings.signUpLink}</Typography>
            </UIBox>
        </Box>
        <Notifications/>
      </Box>
    </Grid>  
  );
}
