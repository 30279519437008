import { useNavigate } from "react-router-dom";

import TopNavigation from "@cloudscape-design/components/top-navigation";

import { Auth } from 'aws-amplify';

import {
    useContextController,
    setNotifications,
  } from "../../context/index";

export default function TopNavigationMenu() {

    const [controller, dispatch] = useContextController();
    const {user} = controller
  
    const navigate = useNavigate();

    function handleSignOut(id){
        switch (id) {
        case "signout":
            setNotifications(dispatch, null)
            Auth.signOut({ global : true })
            navigate("/signin")
            break;
        default:
            break;
        }

    }

    const utilities = [ { type:"button",
                text:"KDDI America",
                href:"https://us.kddi.com/",
                external:true,
                externalIconAriaLabel:" (opens in a new tab)" },
               /* { type:"button",
                iconName:"notification",
                title:"Notifications",
                ariaLabel:"Notifications (unread)",
                badge:true,
                disableUtilityCollapse:false },*/
                /*{ type:"menu-dropdown",
                iconName:"settings",
                ariaLabel:"Settings",
                title:"Settings",
                items:[ 
                    { id:"settings-org",
                    text:"Organizational settings" },
                    { id:"settings-project",
                    text:"Project settings" } ] },*/  
                { type:"menu-dropdown",
                text:user['custom:alias'] + '@' + user['custom:tenantId'],
                description:user['email'],
                iconName:"user-profile",
                onItemClick: ({detail})=>{handleSignOut(detail.id)},
                items:[ 
                   /* { id:"profile",
                    text:"Profile" },
                    { id:"preferences",
                    text:"Preferences" },
                    { id:"security",
                    text:"Security" },*/
                    { id:"support-group",
                    text:"Support",
                    items:[ { id:"documentation",
                        text:"Documentation",
                        href:"https://docs.nucleus.ooo",
                        external:true,
                        externalIconAriaLabel:" (opens in new tab)" },
                        /*{ id:"support",
                        text:"Support" },
                        { id:"feedback",
                        text:"Feedback",
                        href:"#",
                        external:true,
                        externalIconAriaLabel:" (opens in new tab)" }*/ ] },
                    { id:"signout",
                    text:"Sign out",
                    } ] } ]

    return (
        <div id="top-nav">
        <TopNavigation
          identity={{
            logo: { src: '/logo-light.png', alt: 'Nucleus' },
            href: '/home/index.html',
          }}
          i18nStrings={{
            overflowMenuTriggerText: 'More',
            overflowMenuTitleText: 'All',
          }}
        
          utilities={utilities}
        />
      </div>
    )

}