import { useState } from 'react';
import { createErrorNotification } from '../utils/createErrorNotification';
import { v4 as uuid4 } from 'uuid';
//Context
import {
  useContextController,
  setNotifications,
  setIsFetching
} from "../context/index";

export function useApi() {
   //context hook
  const [controller, dispatch] = useContextController();
  const { notifications, isFetching } = controller;
   
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
    isSuccess: false
  })

  async function fetchMethod (apiFunction) {
    setResponse({
      data: response.data,
      isFetching: true,
      error: null,
      isSuccess: false
    });
    setIsFetching(dispatch, true)
    
    try {
      const response = await apiFunction ()
      setResponse({
        data: response,
        isFetching: false,
        error: null,
        isSuccess: true
      });
      setIsFetching(dispatch, false)
      return response
    }
    catch (err) {
      setResponse({
        data: null,
        isFetching: false,
        error: true,
        isSuccess: false
      });
      setIsFetching(dispatch, false)
      notifications[uuid4()] = createErrorNotification(err)
      setNotifications(dispatch, notifications)
    }
  }

  return [response, fetchMethod];
}

