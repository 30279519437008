
export const FILTERING_PROPERTIES = [
  {
    key: "iccid",
    propertyLabel: "iccid",
    groupValuesLabel: "iccids",
    operators: ["!:",":"],
  },
  {
    key: "status",
    propertyLabel: "status",
    groupValuesLabel: "status",
    operators: ["!:",":"],
  },
  {
    key: "sku",
    propertyLabel: "sku",
    groupValuesLabel: "sku",
    operators: ["!:",":"],
  },
  {
    key: "eid",
    propertyLabel: "eid",
    groupValuesLabel: "eid",
    operators: ["!:",":"],
  },
];