export const signinI18nStrings = {
    coverHeader: 'Welcome to KDDI Console',
    coverDescription: 'IoT connectivity evrywhere, provided by anyone',
    signInHeaderForm: "Welcome back",
    signInDescriptionForm: "Please sign in using the form below",
    signInAccountNumberLabel: "Account number",
    signInUserNameLabel: "User name",
    signInPasswordLabel: "Password",
    signInSwap: "Don't have an account? ",
    signInLink: "Sign up",
    signUpHeaderForm: "Join Nucleus service",
    signUpDescriptionForm: "Please sign up using the form below",
    signUpAccountNameLabel: "Account name",
    signUpUserNameLabel: "User name",
    signUpEmailLabel: "Email",
    signUpSwap: "Do you have an account? ",
    signUpLink: "Sign in",
    formValidationError: "Field cannot be empty"
  };