
import Button from "@cloudscape-design/components/button";

//API calls
import { executeCommand } from "../../../api/nucleus";
import { useApiWithFeedback } from "../../../hooks/useApiWithFeedback";
//Context
import {
    useContextController,
    setNotifications,
  } from "../../../context/index";

export default function PanelButton({text, eid, iccid, disabled, deviceId}){

    //context hook
    const [controller, dispatch] = useContextController();
    const { notifications } = controller;
    
    const[,setCommandResponse] = useApiWithFeedback()

    function notificationCallback(notification) {
        const keys = Object.keys(notification)
        keys.forEach(key => {
            notifications[key] = notification[key]
          });
        setNotifications(dispatch, notifications)
    }

    function handleClick(){
        const payload = {
            'iccid': iccid,
            'eid': eid,
            'deviceId': deviceId
        }
        setCommandResponse (() => executeCommand(text, payload, notificationCallback)); 
    }
      
    return <Button variant="primary" onClick={handleClick} disabled={disabled} >{text}</Button>

}