import { passwordResetI18nStrings } from '../../../i18-strings/passwordReset'


export const PASSWORD_RESET_FORM_CONFIG = [
    {
      id: 'oldPassword',
      label: passwordResetI18nStrings.oldPasswordLabel,
      focusId: 'focusOldPassword',
      seeId: 'seeOldPassword'
    },
    {
      id: 'newPassword',
      label: passwordResetI18nStrings.newPasswordLabel,
      focusId: 'focusNewPassword',
      seeId: 'seeNewPassword'
    },
    {
      id: 'newPasswordConfirmation',
      label: passwordResetI18nStrings.confirmNewPasswordLabel,
      focusId: 'focusNewPasswordConfirmation',
      seeId: 'seeNewPasswordConfirmation'
    }
  ]
  
/*export function validateOnlyEmptyFields(state, setState) {
  let validationError = false
  const newState = { ...state };
  for (let key in state) {
    if (state[key]=== null || state[key]=== "") {
     validationError = true
     newState['isEmpty'+key] = true
    }
  }

  setState(newState)

  return validationError
}*/
  
 